import { html } from "htm/preact";
import { NavigationButton, NavigationButtonTypeEnum } from "./navigationButton.js";



export function NavigationBar(props) {

	let mainNavigationNextActive = props.NextQuestionNavigationAllowed;
	let additionalNavigationNextActive = props.QuestionValidationAvailable || props.NextQuestionNavigationAllowed;

	let additionalClass = !props.NextQuestionNavigationAllowed && props.QuestionValidationAvailable ? "questionnaire-question-navigation-no-navigation questionnaire-question-navigation-validation" : "";

	return html`
		<div class="questionnaire-navigation-area-view">
			<div class="questionnaire-navigation-additional-controls-view">
				${props.additionalNextButton
					? !props.QuestionLast
						? html`<${NavigationButton} 
									type=${NavigationButtonTypeEnum.QuestionnaireAdditionalNextQuestion} 
									onNavigationButtonClick=${props.onNextQuestion} 
									active=${additionalNavigationNextActive} 
									additionalClass=${additionalClass}
									reportViewNavigationStripeButtonText=${props.reportViewNavigationStripeButtonText}
									reportViewButtonText=${props.reportViewButtonText}
								/>`
						: html`<${NavigationButton} 
									type=${NavigationButtonTypeEnum.QuestionnaireAdditionalResults} 
									onNavigationButtonClick=${props.onResults} 
									active=${additionalNavigationNextActive} 
									additionalClass=${additionalClass} 
									reportViewNavigationStripeButtonText=${props.reportViewNavigationStripeButtonText}
									reportViewButtonText=${props.reportViewButtonText}
								/>`
					: null}
			</div>

			<div class="questionnaire-navigation-bar-view">
				<${NavigationButton} 
					type=${NavigationButtonTypeEnum.QuestionnairePreviousQuestion} 
					onNavigationButtonClick=${props.onPreviousQuestion} 
					active=${!props.QuestionFirst} 
					visible=${!props.QuestionFirst}
					reportViewNavigationStripeButtonText=${props.reportViewNavigationStripeButtonText}
					reportViewButtonText=${props.reportViewButtonText}
				/>

				<${NavigationStripe} 
					NumberOfSections=${props.NumberOfSections} 
					ActiveSectionOrder=${props.ActiveSectionOrder}
				/>

				${!props.QuestionLast
					? html`<${NavigationButton} 
								type=${NavigationButtonTypeEnum.QuestionnaireNextQuestion} 
								onNavigationButtonClick=${props.onNextQuestion} 
								active=${mainNavigationNextActive} 
								additionalClass=${additionalClass} 
								reportViewNavigationStripeButtonText=${props.reportViewNavigationStripeButtonText}
								reportViewButtonText=${props.reportViewButtonText}
							/>`
					: html`<${NavigationButton} 
								type=${NavigationButtonTypeEnum.QuestionnaireResults} 
								onNavigationButtonClick=${props.onResults}
								active=${mainNavigationNextActive} 
								additionalClass=${additionalClass} 
								reportViewNavigationStripeButtonText=${props.reportViewNavigationStripeButtonText}
								reportViewButtonText=${props.reportViewButtonText}
							/>`
				}
			</div>

			<${NavigationPages} NumberOfPages=${props.NumberOfQuestions} CurrentPage=${props.CurrentQuestionOrder}/>
		</div>
		`;
}


export function NavigationStripe(props) {
	let sections = new Array(props.NumberOfSections).fill(null); //CurrentQuestionOrder

	return html`
			<div class="questionnaire-navigation-stripe-view">
				<div class="questionnaire-navigation-stripe-initial-section"></div>
				${sections.map((_, index) => html`
					${props.ActiveSectionOrder == index + 1
						? html`<div key=${`m${index}`} class="questionnaire-navigation-stripe-selected-marker-section"></div>`
						: html`<div key=${`m${index}`} class="questionnaire-navigation-stripe-marker-section"></div>`
					}
					${index < props.NumberOfSections - 1
						? html`<div key=${`j${index}`} class="questionnaire-navigation-stripe-join-section"></div>`
						: html``
					}`
				)}
				<div class="questionnaire-navigation-stripe-final-section"></div>
			</div>
	`
}

export function NavigationPages(props) {
	return html`
		<div class="questionnaire-navigation-information-view">
			<div class="questionnaire-navigation-information text-questionnaire-navigation-information">${props.CurrentPage}/${props.NumberOfPages}</div>
		</div>
	`
}