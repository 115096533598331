import { html } from "htm/preact";



export let NavigationButtonTypeEnum = {
	Undefined: 0,
	BeginQuestionnaire: 1,
	QuestionnaireNextQuestion: 2,
	QuestionnaireAdditionalNextQuestion: 3,
	QuestionnairePreviousQuestion: 4,
	QuestionnaireResults: 5,
	QuestionnaireAdditionalResults: 6,
	ReportBackToLastQuestion: 7,
	ReportBackToFirstQuestion: 8,
	ReportNextSection: 9,
	ReportPreviousSection: 10,
	ReportComplete: 11
}





export function NavigationButton(props) {
	let active = (props.active == undefined || props.active == null) ? true : props.active;
	let visible = (props.visible == undefined || props.visible == null) ? true : props.visible;
	let additionalClass = (props.additionalClass == undefined || props.additionalClass == null) ? "" : props.additionalClass;

	if (props.type == NavigationButtonTypeEnum.BeginQuestionnaire) {
		let className = "questionnaire-welcome-begin-questionnaire-button text-questionnaire-welcome-begin-questionnaire-button";
		className += !active ? " questionnaire-button-inactive" : "";
		className += !visible ? " questionnaire-button-hidden" : "";
		className += additionalClass != "" ? " " + additionalClass : "";

		if (active) return html`<div class=${className} onClick=${props.onNavigationButtonClick}>Begin</div>`;
		return html`<div class=${className}>Begin</div>`;
	}
	if (props.type == NavigationButtonTypeEnum.QuestionnaireNextQuestion) {
		let className = "questionnaire-question-navigation-next-button text-questionnaire-question-navigation-next-button";
		className += !visible ? " questionnaire-button-hidden" : "";
		className += !active ? " questionnaire-button-inactive" : "";
		className += additionalClass != "" ? " " + additionalClass : "";

		if (active) return html`<div class=${className} onClick=${props.onNavigationButtonClick}></div>`;
		return html`<div class=${className}></div>`;
	}
	if (props.type == NavigationButtonTypeEnum.QuestionnaireAdditionalNextQuestion) {
		let className = "questionnaire-question-navigation-next-additional-button text-questionnaire-question-navigation-next-additional-button"
		className += !visible ? " questionnaire-button-hidden" : "";
		className += !active ? " questionnaire-button-inactive" : "";
		className += additionalClass != "" ? " " + additionalClass : "";

		if (active) return html`<div class=${className} onClick=${props.onNavigationButtonClick}>Next</div>`;
		return html`<div class=${className}>Next</div>`;
	}
	if (props.type == NavigationButtonTypeEnum.QuestionnairePreviousQuestion) {
		let className = "questionnaire-question-navigation-previous-button text-questionnaire-question-navigation-previous-button";
		className += !visible ? " questionnaire-button-hidden" : "";
		className += !active ? " questionnaire-button-inactive" : "";
		className += additionalClass != "" ? " " + additionalClass : "";

		if (active) return html`<div class=${className} onClick=${props.onNavigationButtonClick}></div>`;
		return html`<div class=${className}></div>`;
	}
	if (props.type == NavigationButtonTypeEnum.QuestionnaireResults) {
		let className = "questionnaire-results-button text-questionnaire-results-button";
		className += !visible ? " questionnaire-button-hidden" : "";
		className += !active ? " questionnaire-button-inactive" : "";
		className += additionalClass != "" ? " " + additionalClass : "";

		if (active) return html`<div class=${className} onClick=${props.onNavigationButtonClick}>${props.reportViewNavigationStripeButtonText}</div>`;
		return html`<div class=${className}}>${props.reportViewNavigationStripeButtonText}</div>`;
	}
	if (props.type == NavigationButtonTypeEnum.QuestionnaireAdditionalResults) {
		let className = "questionnaire-results-additional-button text-questionnaire-results-additional-button"
		className += !visible ? " questionnaire-button-hidden" : "";
		className += !active ? " questionnaire-button-inactive" : "";
		className += additionalClass != "" ? " " + additionalClass : "";

		if (active) return html`<div class=${className} onClick=${props.onNavigationButtonClick}>${props.reportViewButtonText}</div>`;
		return html`<div class=${className}>${props.reportViewButtonText}</div>`;
	}
	if (props.type == NavigationButtonTypeEnum.ReportBackToLastQuestion) {
		let className = "questionnaire-report-back-to-last-question-button text-questionnaire-report-back-to-last-question-button";
		className += !visible ? " questionnaire-button-hidden" : "";
		className += !active ? " questionnaire-button-inactive" : "";
		className += additionalClass != "" ? " " + additionalClass : "";

		if (active) return html`<div class=${className} onClick=${props.onNavigationButtonClick}>Back</div>`;
		return html`<div class=${className}}>Back</div>`;
	}
	if (props.type == NavigationButtonTypeEnum.ReportBackToFirstQuestion) {
		let className = "questionnaire-report-back-to-first-question-button text-questionnaire-report-back-to-first-question-button";
		className += !visible ? " questionnaire-button-hidden" : "";
		className += !active ? " questionnaire-button-inactive" : "";
		className += additionalClass != "" ? " " + additionalClass : "";

		if (active) return html`<div class=${className} onClick=${props.onNavigationButtonClick}>Review my answers</div>`;
		return html`<div class=${className}}>Review my answers</div>`;
	}
	if (props.type == NavigationButtonTypeEnum.ReportNextSection) {
		let className = "questionnaire-report-next-section-button text-questionnaire-report-next-section-button";
		className += !visible ? " questionnaire-button-hidden" : "";
		className += !active ? " questionnaire-button-inactive" : "";
		className += additionalClass != "" ? " " + additionalClass : "";

		if (active) return html`<div class=${className} onClick=${props.onNavigationButtonClick}>Next</div>`;
		return html`<div class=${className}}>Next</div>`;
	}
	if (props.type == NavigationButtonTypeEnum.ReportPreviousSection) {
		let className = "questionnaire-report-previous-section-button text-questionnaire-report-previous-section-button";
		className += !visible ? " questionnaire-button-hidden" : "";
		className += !active ? " questionnaire-button-inactive" : "";
		className += additionalClass != "" ? " " + additionalClass : "";

		if (active) return html`<div class=${className} onClick=${props.onNavigationButtonClick}>Previous</div>`;
		return html`<div class=${className}}>Previous</div>`;
	}
	if (props.type == NavigationButtonTypeEnum.ReportComplete) {
		let className = "questionnaire-report-complete-button text-questionnaire-report-complete-button";
		className += !visible ? " questionnaire-button-hidden" : "";
		className += !active ? " questionnaire-button-inactive" : "";
		className += additionalClass != "" ? " " + additionalClass : "";

		if (active) return html`<a class=${className} onClick=${props.onNavigationButtonClick} href=${props.url}>Continue</a>`;
		return html`<div class=${className}}>Continue</div>`;
	}
}