import Cookies from 'js-cookie';




// TODO vai Cookie b�tu j�glab� orderId - vai pareiz�k neb�tu to ar� atlas�t servis� p�c clientId l�dz�gi k� anketas skat� atlasa akt�vo anketu
// TODO kur� br�d� no Cookie b�tu j�iz�em specialOffers
export default class Session {
	guidEmpty = '00000000-0000-0000-0000-000000000000';
	defaultLanguage = 'default';
	defaultCurrency = 'default';



	getCookie(cookieData = null) {
		//https://stackoverflow.com/a/13653180
		const guidRegex = RegExp(/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i);



		let clientId = null,
			order = { orderId: null },
			productOffersSetId = null,
			specialOffersSetId = null,
			specialOffers = { specialOffersIds: [], discountCodesIds: [], discountCodes: [] },
			//questionnaires = [], // of {questionnaire (slug), questionnaireId, questionnaireTakeId}
			language = null,
			currency = null,
			testMode = false;



		let cookie = cookieData === null ? Cookies.get('matterboonServiceInfo') : null;


		if (cookieData !== null || (typeof cookie !== 'undefined' && cookie != null)) {
			if (cookieData === null) cookieData = JSON.parse(cookie);


			if (typeof cookieData === 'object' && cookieData !== null) {
				if (cookieData.clientId && guidRegex.test(cookieData.clientId.trim())) clientId = cookieData.clientId.trim();

				if (typeof cookieData.order === 'object' && cookieData.order !== null && typeof cookieData.order.orderId === 'string' && guidRegex.test(cookieData.order.orderId.trim())) {
					order = cookieData.order;
					order.orderId = order.orderId.trim();
				}


				if (cookieData.productOffersSetId && guidRegex.test(cookieData.productOffersSetId.trim())) productOffersSetId = cookieData.productOffersSetId.trim();

				if (cookieData.specialOffersSetId && guidRegex.test(cookieData.specialOffersSetId.trim())) specialOffersSetId = cookieData.specialOffersSetId.trim();


				if (typeof cookieData.specialOffers === 'object' && cookieData.specialOffers !== null) {
					let specialOffersIds = [];
					if (Array.isArray(cookieData.specialOffers.specialOffersIds) && cookieData.specialOffers.specialOffersIds.length > 0) {
						cookieData.specialOffers.specialOffersIds.forEach(soid => { if (soid && guidRegex.test(soid.trim())) specialOffersIds.push(soid.trim()); });
					}
					let discountCodesIds = [];
					if (Array.isArray(cookieData.specialOffers.discountCodesIds) && cookieData.specialOffers.discountCodesIds.length > 0) {
						cookieData.specialOffers.discountCodesIds.forEach(dcid => { if (dcid && guidRegex.test(dcid.trim())) discountCodesIds.push(dcid.trim()); });
					}
					let discountCodes = [];
					if (Array.isArray(cookieData.specialOffers.discountCodes) && cookieData.specialOffers.discountCodes.length > 0) {
						cookieData.specialOffers.discountCodes.forEach(dc => { if (dc && dc.trim().length > 0) discountCodes.push(dc.trim()); });
					}

					specialOffers = { specialOffersIds: specialOffersIds, discountCodesIds: discountCodesIds, discountCodes: discountCodes };
				}


				//if (Array.isArray(cookieData.questionnaires) && cookieData.questionnaires.length > 0) {
				//	cookieData.questionnaires.forEach(q => {
				//		if (typeof q === 'object' && q !== null && ((q.questionnaire && q.questionnaire.trim().length > 0) || (q.questionnaireId && guidRegex.test(q.questionnaireId.trim())))) {
				//			questionnaires.push(
				//				{
				//					questionnaire: (q.questionnaire && q.questionnaire.trim().length > 0) ? q.questionnaire.trim() : null,
				//					questionnaireId: (q.questionnaireId && guidRegex.test(q.questionnaireId.trim())) ? q.questionnaireId.trim() : null,
				//					questionnaireTakeId: (q.questionnaireTakeId && guidRegex.test(q.questionnaireTakeId.trim())) ? q.questionnaireTakeId.trim() : null,
				//              }
				//			);
				//		}
				//	});
				//}


				if (cookieData.language && cookieData.language.trim().length > 0) language = cookieData.language.trim();

				if (cookieData.currency && cookieData.currency.trim().length > 0) currency = cookieData.currency.trim();


				if (typeof cookieData.testMode != "undefined" && cookieData.testMode != null) testMode = Boolean(cookieData.testMode);
			}
		}





		cookie = Cookies.get('_fbp');

		let fbp = null;
		if (typeof cookie !== 'undefined' && cookie != null) {
			fbp = cookie;
		}



		cookie = Cookies.get('_fbc');

		let fbc = null;
		if (typeof cookie !== 'undefined' && cookie != null) {
			fbc = cookie;
		}





		return {
			clientId: clientId,
			order: order,
			productOffersSetId: productOffersSetId,
			specialOffersSetId: specialOffersSetId,
			specialOffers: specialOffers,
			//questionnaires: questionnaires,
			language: language,
			currency: currency,
			testMode: testMode,

			fbp: fbp,
			fbc: fbc
		};
	}





	// Does not return fbp and fbc!
	// Set order = { orderId: null } to clear order information
	setCookie(overwrite, clientId, testMode = null, order = null /* { orderId } */, productOffersSetId = null, specialOffersSetId = null, specialOffers = null, overwriteSpecialOffers = false, /*questionnaires = null, overwriteQuestionnaires = false,*/ language = null, currency = null) {
		//https://stackoverflow.com/a/13653180
		const guidRegex = RegExp(/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i);

		if (!clientId || !guidRegex.test(clientId.trim())) return;





		let cookie = Cookies.get('matterboonServiceInfo');
		let cookieData = null;

		if (!overwrite && typeof cookie !== 'undefined' && cookie != null) {
			cookieData = JSON.parse(cookie);

			if (typeof cookieData === 'object' && cookieData !== null) {
				cookieData.clientId = clientId.trim();
			} else {
				cookieData = null;
			}
		}

		if (cookieData == null) cookieData = { clientId: clientId.trim() };





		if (typeof order === 'object' && order !== null) {
			if (order.orderId === null) {
				cookieData.order = order;
			} else if (typeof order.orderId === 'string' && guidRegex.test(order.orderId.trim())) {
				order.orderId = order.orderId.trim();
				cookieData.order = order;
			}
		}


		if (productOffersSetId != null && guidRegex.test(productOffersSetId.trim())) {
			cookieData.productOffersSetId = productOffersSetId.trim();
		}

		if (specialOffersSetId != null && guidRegex.test(specialOffersSetId.trim())) {
			cookieData.specialOffersSetId = specialOffersSetId.trim();
		}


		if (overwriteSpecialOffers
			|| (typeof specialOffers === 'object' && specialOffers !== null
				&& ((Array.isArray(specialOffers.specialOffersIds) && specialOffers.specialOffersIds.some(soid => soid && guidRegex.test(soid.trim())))
					|| (Array.isArray(specialOffers.discountCodesIds) && specialOffers.discountCodesIds.some(dcid => dcid && guidRegex.test(dcid.trim())))
					|| (Array.isArray(specialOffers.discountCodes) && specialOffers.discountCodes.some(dc => dc && dc.trim().length > 0))))
		) {

			if (typeof specialOffers !== 'object' || specialOffers === null) specialOffers = {};
			if (!Array.isArray(specialOffers.specialOffersIds)) specialOffers.specialOffersIds = [];
			specialOffers.specialOffersIds = specialOffers.specialOffersIds.map(soid => soid ? soid.trim() : '');
			specialOffers.specialOffersIds.filter(soid => guidRegex.test(soid));
			if (!Array.isArray(specialOffers.discountCodesIds)) specialOffers.discountCodesIds = [];
			specialOffers.discountCodesIds = specialOffers.discountCodesIds.map(dcid => dcid ? dcid.trim() : '');
			specialOffers.discountCodesIds.filter(dcid => guidRegex.test(dcid));
			if (!Array.isArray(specialOffers.discountCodes)) specialOffers.discountCodes = [];
			specialOffers.discountCodes = specialOffers.discountCodes.map(soid => soid ? soid.trim() : '');
			specialOffers.discountCodes.filter(dc => dc.length > 0);

			if (overwriteSpecialOffers || typeof cookieData.specialOffers !== 'object' || cookieData.specialOffers === null) {
				cookieData.specialOffers = specialOffers;
			} else {
				if (specialOffers.specialOffersIds.length > 0) {
					if (Array.isArray(cookieData.specialOffers.specialOffersIds) && cookieData.specialOffers.specialOffersIds.length > 0) {
						// https://stackoverflow.com/a/27997088 however spread operator does not work to convert set back to array, producing some object, Array.from(s) does not work creating array with set as 0th element (specific to bundling as in browser console it works)
						let s = new Set([...cookieData.specialOffers.specialOffersIds, ...specialOffers.specialOffersIds]);
						let a = [];
						s.forEach(e => a.push(e));
						cookieData.specialOffers.specialOffersIds = a; //Array.from(s);// [...s];
					} else {
						cookieData.specialOffers.specialOffersIds = specialOffers.specialOffersIds;
					}
				}

				if (specialOffers.discountCodesIds.length > 0) {
					if (Array.isArray(cookieData.specialOffers.discountCodesIds) && cookieData.specialOffers.discountCodesIds.length > 0) {
						let s = new Set([...cookieData.specialOffers.discountCodesIds, ...specialOffers.discountCodesIds]);
						let a = [];
						s.forEach(e => a.push(e));
						cookieData.specialOffers.discountCodesIds = a;
					} else {
						cookieData.specialOffers.discountCodesIds = specialOffers.discountCodesIds;
					}
				}

				if (specialOffers.discountCodes.length > 0) {
					if (Array.isArray(cookieData.specialOffers.discountCodes) && cookieData.specialOffers.discountCodes.length > 0) {
						let s = new Set([...cookieData.specialOffers.discountCodes, ...specialOffers.discountCodes]);
						let a = [];
						s.forEach(e => a.push(e));
						cookieData.specialOffers.discountCodes = a;
					} else {
						cookieData.specialOffers.discountCodes = specialOffers.discountCodes;
					}
				}
			}
		}


		//if (Array.isArray(questionnaires)
		//	&& questionnaires.length > 0
		//	&& questionnaires.some(q => (q.questionnaire && q.questionnaire.trim().length > 0) || (q.questionnaireId && guidRegex.test(q.questionnaireId.trim())) || (q.questionnaireTakeId && guidRegex.test(q.questionnaireTakeId.trim())))
		//	) {

		//	questionnaires = questionnaires.map(q => ({ questionnaire: q.questionnaire.trim(), questionnaireId: q.questionnaireId.trim(), questionnaireTakeId: q.questionnaireTakeId.trim() }));
		//	questionnaires.filter(q => (q.questionnaire && q.questionnaire.length > 0) || (q.questionnaireId && guidRegex.test(q.questionnaireId)) || (q.questionnaireTakeId && guidRegex.test(q.questionnaireTakeId)));


		//	if (overwriteQuestionnaires || !Array.isArray(cookieData.questionnaires) || cookieData.questionnaires.length == 0) {
		//		cookieData.questionnaires = questionnaires;
		//	} else {
		//		for (let i = questionnaires.length - 1; i >= 0; i--) {
		//			let index = cookieData.questionnaires.findIndex(q =>
		//				(q.questionnaireId && guidRegex.test(q.questionnaireId.trim()) && questionnaires[i].questionnaireId && q.questionnaireId.trim().toLowerCase() == questionnaires[i].questionnaireId.toLowerCase())
		//				|| ((!q.questionnaireId || !guidRegex.test(q.questionnaireId.trim()))
		//					&& q.questionnaire && q.questionnaire.trim().length > 0 && questionnaires[i].questionnaire && q.questionnaire.trim().toLowerCase() == questionnaires[i].questionnaire.toLowerCase())
		//				|| ((!q.questionnaireId || !guidRegex.test(q.questionnaireId.trim())) && (!q.questionnaire || q.questionnaire.trim().length == 0)
		//					&& q.questionnaireTakeId && guidRegex.test(q.questionnaireTakeId.trim()) && questionnaires[i].questionnaireTakeId && q.questionnaireTakeId.trim().toLowerCase() == questionnaires[i].questionnaireTakeId.toLowerCase())
		//			);

		//			if (index >= 0) cookieData.questionnaires[index] = questionnaires[i];
		//			else cookie.push(questionnaires[i]);
		//		}
		//	}
		//}


		if (language != null && language.trim().length > 0) {
			cookieData.language = language.trim();
		}

		if (currency != null && currency.trim().length > 0) {
			cookieData.currency = currency.trim();
		}


		if (testMode === true || (testMode === false && cookieData.testMode === true)) {
			cookieData.testMode = testMode;
		}



		Cookies.set('matterboonServiceInfo', JSON.stringify(cookieData), { expires: 21 });



		return this.getCookie(cookieData);
	}





	getQueryStringParameters() {
		let params = new URLSearchParams(window.location.search);

		//https://stackoverflow.com/a/13653180
		const guidRegex = RegExp(/[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}/i);


		let specialOffersIds = params.getAll('soid').map(soid => soid && guidRegex.test(soid.trim()) ? soid.trim() : null).filter(soid => soid !== null);
		let discountCodesIds = params.getAll('dcid').map(dcid => dcid && guidRegex.test(dcid.trim()) ? dcid.trim() : null).filter(dcid => dcid !== null);
		let discountCodes = params.getAll('dc').map(dcid => dcid && dcid.trim().length > 0 ? dcid.trim() : null).filter(dc => dc !== null);



		let question = params.get('question') && params.get('question').trim().length > 0 ? params.get('question').trim().toLowerCase() : null;
		if (question !== null && question !== 'first' && question !== 'last') question = parseInt(question, 10);
		if (isNaN(question)) question = null;
		let page = params.get('page') && params.get('page').trim().length > 0 ? params.get('page').trim().toLowerCase() : null;
		if (page !== null && page !== 'first' && page !== 'last') page = parseInt(page, 10);
		if (isNaN(page)) page = null;
		let view = params.get('view') ? parseInt(params.get('view'), 10) : null;
		if (isNaN(view)) view = null;
		let checkoutStep = params.get('checkoutStep') ? parseInt(params.get('checkoutStep'), 10) : null;
		if (isNaN(checkoutStep)) checkoutStep = null;

		return {
			page: window.location.pathname,

			clientId: params.get('c') && guidRegex.test(params.get('c').trim()) ? params.get('c').trim() : null,
			orderId: params.get('o') && guidRegex.test(params.get('o').trim()) ? params.get('o').trim() : null,
			productOffersSetId: params.get('pos') && guidRegex.test(params.get('pos').trim()) ? params.get('pos').trim() : null,
			specialOffersSetId: params.get('sos') && guidRegex.test(params.get('sos').trim()) ? params.get('sos').trim() : null,
			specialOffersIds: specialOffersIds.length > 0 ? specialOffersIds : null,
			discountCodesIds: discountCodesIds.length > 0 ? discountCodesIds : null,
			discountCodes: discountCodes.length > 0 ? discountCodes : null,
			language: params.get('language') && params.get('language').trim().length > 0 ? params.get('language').trim() : null,
			currency: params.get('currency') && params.get('currency').trim().length > 0 ? params.get('currency').trim() : null,
			testMode: typeof params.get('test') != 'string' ? null : (params.get('test').toLowerCase() === 'true' ? true : (params.get('test').toLowerCase() === 'false' ? false : null)),

			questionnaireId: params.get('qid') && guidRegex.test(params.get('qid').trim()) ? params.get('qid').trim() : null,
			questionnaireTakeId: params.get('qtid') && guidRegex.test(params.get('qtid').trim()) ? params.get('qtid').trim() : null,
			//questionnaireTakeId: params.get('qt') && guidRegex.test(params.get('qt').trim()) ? params.get('qt').trim() : null,
			view: view,
			question: question,
			viewPage: page,
			reportType: params.get('r') && params.get('r').trim().length > 0 ? params.get('r').trim() : null,

			checkoutStep: checkoutStep,
			paymentStatus: params.get('paymentStatus') && params.get('paymentStatus').trim().length > 0 ? params.get('paymentStatus').trim().toLowerCase() : null,
			paymentProvider: params.get('paymentProvider') && params.get('paymentProvider').trim().length > 0 ? params.get('paymentProvider').trim().toLowerCase() : null,
			orderCompletedId: params.get('orderCompletedId') && guidRegex.test(params.get('orderCompletedId').trim()) ? params.get('orderCompletedId').trim() : null,

			fbc: params.get('fbclid') && params.get('fbclid').trim().length > 0 ? params.get('fbclid').trim() : null,

			liveChat: typeof params.get('chat') != 'string' ? null : (params.get('chat').toLowerCase() === 'true' ? true : (params.get('chat').toLowerCase() === 'false' ? false : null)),
		}
	}





	changeBrowserHistoryState(paramsToAddState = null /* object */, paramsToRemoveState = null /* array */, paramsToAddUrl = null /* object */, paramsToRemoveUrl = null /* array */, push = true) {
		let initialState = history.state;
		if (typeof initialState === 'undefined' || initialState === null) initialState = {};

		if (Object.keys(initialState).length > 0 && typeof paramsToRemoveState !== 'undefined' && paramsToRemoveState !== null) {
			paramsToRemoveState.forEach(param => {
				if (typeof initialState[param] != 'undefined') {
					delete initialState[param];
				}
			});
		}

		let state;
		if (typeof paramsToAddState !== 'undefined' && paramsToAddState !== null) {
			state = { ...initialState, ...paramsToAddState };
		} else {
			state = initialState;
		}



		let urlparams = new URLSearchParams(window.location.search);

		if (typeof paramsToRemoveUrl !== 'undefined' && paramsToRemoveUrl !== null) {
			paramsToRemoveUrl.forEach(param => {
				if (urlparams.has(param)) {
					urlparams.delete(param);
				}
			});
		}

		if (typeof paramsToAddUrl !== 'undefined' && paramsToAddUrl !== null) {
			for (const [key, value] of Object.entries(paramsToAddUrl)) {
				if (urlparams.has(key)) {
					urlparams.set(key, value);
				} else {
					urlparams.append(key, value);
				}
			}
		}



		if (push) {
			history.pushState(state, null, `${location.pathname}?${urlparams.toString()}`);
		} else {
			history.replaceState(state, null, `${location.pathname}?${urlparams.toString()}`);
		}
	}



	removeQueryStringParameters(paramsToRemove) {
		this.changeBrowserHistoryState(null, null, null, paramsToRemove, false);

		/*if (typeof paramsToRemove === 'string') {
			paramsToRemove = [paramsToRemove];
		}
		if (!Array.isArray(paramsToRemove)) return;

		let url = new URL(window.location);
		let params = new URLSearchParams(url.search);
		

		paramsToRemove.forEach(param => params.delete(param));

		url.search = params.toString();

		history.replaceState(null, "", url.toString());*/
	}










	checkClient = (clientId) => fetch(`../Client/Check/${clientId}`).then(response => response.json())

	getClient = (clientId) => fetch(`../Client/${clientId}`).then(response => response.json())

	createClient = () => fetch('../Client/Create').then(response => response.json())

	getNumberOfOrderProuctOffers = (clientId, orderId) => fetch(`../Checkout/GetNumberOfProuctOffers/${clientId}/${orderId}`).then(response => response.json())

	addVisitInfo = async (clientId, eventId, fbp, fbc, data) => {
		try {
			await fetch(`../Client/AddVisitInfo/${clientId}/${eventId}/${fbp}/${fbc}`, {
				method: 'POST',
				headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
				body: JSON.stringify(data),
			});
		} catch {

		}
	}

	//checkQuestionnaireTake = (clientId, questionnaireTakeId) => fetch('../Questionnaire/GetQuestionnaireTakeInformation/' + (clientId != null ? (clientId + '/') : '') + questionnaireTakeId).then(response => response.json())





	// Ensures clientId is set, updates cookie if url query string parameters are provided, adds visit information and returns values from query string parameters and cookie
	async prepareSession() {
		let queryStringParams = this.getQueryStringParameters(); // clientId, orderId, productOffersSetId, specialOffersSetId, specialOffersIds, discountCodesIds, discountCodes, language, currency, testMode, reportType, -questionnaireTakeId
		let cookieData = this.getCookie(); // clientId, order, productOffersSetId, specialOffersSetId, specialOffers, -questionnaires, language, currency, testMode



		// If clientId is provided with query string, it must match clientId linked to questionnaireTakeId, if query string does not contain clientId or it match, then clientId of questionnaireTake is used
		//let quetionnaireTakeInfo = null;
		//if (queryStringParams.questionnaireTakeId != null) {
		//	quetionnaireTakeInfo = await checkQuestionnaireTake(queryStringParams.clientId != null, queryStringParams.questionnaireTakeId);
		//	if (!quetionnaireTakeInfo || !quetionnaireTakeInfo.clientId || !quetionnaireTakeInfo.questionnaireId) quetionnaireTakeInfo = null;
		//}
		//let clientId = quetionnaireTakeInfo != null ? quetionnaireTakeInfo.clientId : null;



		let clientId = null;
		let client = null;
		let language = queryStringParams.language !== null ? queryStringParams.language : cookieData.language;
		let currency = queryStringParams.currency !== null ? queryStringParams.currency : cookieData.currency;
		let emailHashed = null;
		try {
			//if (queryStringParams.clientId !== null
			//	&& (await this.checkClient(queryStringParams.clientId))) clientId = queryStringParams.clientId;
			if (queryStringParams.clientId !== null) {
				client = await this.getClient(queryStringParams.clientId);
				if (client !== null) clientId = queryStringParams.clientId;
			}

			//if (clientId === null
			//	&& cookieData.clientId !== null
			//	&& (await this.checkClient(cookieData.clientId))) clientId = cookieData.clientId;
			if (clientId === null && cookieData.clientId !== null) {
				client = await this.getClient(cookieData.clientId);
				if (client !== null) clientId = cookieData.clientId;
			}

			//if (clientId !== null && (language === null || currency === null)) {
			//	client = await this.getClient(clientId);
			//	if (typeof client !== 'undefined' && client !== null) {
			//		if (language === null) language = client.language;
			//		if (currency === null) currency = client.currency;
			//	}
			//}

			if (client !== null) {
				if (language === null) language = client.language;
				if (currency === null) currency = client.currency;
				emailHashed = client.emailHashed;
			}


			if (clientId === null) {
				client = await this.createClient();
				clientId = client.id;
				language = client.language;
				currency = client.currency;
				emailHashed = null;
			}
		} catch {
			// TODO
		}



		let fbp = cookieData.fbp;
		let fbc = queryStringParams.fbc !== null ? queryStringParams.fbc : cookieData.fbc;



		if (clientId !== null &&
			(cookieData.clientId != clientId
				|| queryStringParams.testMode !== null
				|| queryStringParams.orderId !== null
				|| queryStringParams.productOffersSetId !== null
				|| queryStringParams.specialOffersSetId !== null
				|| queryStringParams.specialOffersIds !== null
				|| queryStringParams.discountCodesIds !== null
				|| queryStringParams.discountCodes !== null
				|| (language !== null && cookieData.language != language)
				|| (currency !== null && cookieData.currency != currency)
				//|| queryStringParams.questionnaireTakeId !== null
				//|| queryStringParams.reportType !== null
			)
		) {
			cookieData = this.setCookie(
				cookieData.clientId !== clientId,
				clientId,
				queryStringParams.testMode,
				queryStringParams.orderId !== null ? { orderId: queryStringParams.orderId } : null,
				queryStringParams.productOffersSetId,
				queryStringParams.specialOffersSetId,
				(queryStringParams.specialOffersIds !== null || queryStringParams.discountCodesIds !== null || queryStringParams.discountCodes !== null) ? { specialOffersIds: queryStringParams.specialOffersIds, discountCodesIds: queryStringParams.discountCodesIds, discountCodes: queryStringParams.discountCodes } : null,
				false,
				//quetionnaireTakeInfo != null ? { questionnaire: quetionnaireTakeInfo.questionnaireSlug, questionnaireId: quetionnaireTakeInfo.questionnaireId, questionnaireTakeId: queryStringParams.questionnaireTakeId } : null,
				//false,
				language,
				currency,
			);
		}



		if (language === null) language = this.defaultLanguage;
		if (currency === null) currency = this.defaultLanguage;







		let externalId = await this.encodeSha256(clientId);
		let eventId = this.uuidv4();
		if (typeof window.fbq !== 'undefined') {
			window.fbq('init', '2253240321656053', { 'external_id': externalId, 'fbp': fbp, 'fbc': fbc, 'em': emailHashed });
			window.fbq.disablePushState = true;

			if (cookieData.testMode !== true) {
				////ReactPixel.pageView();
				//ReactPixel.track('PageView', { external_id: externalId, extern_id: externalId });
				window.fbq('track', 'PageView', {}, { eventID: eventId });
			}
		}

		if (cookieData.testMode !== true) {
			let data = {
				url: window.location.href,
				page: queryStringParams.page,
			};

			/* await */ this.addVisitInfo(clientId, eventId, fbp, fbc, data);
		}



		if (queryStringParams.liveChat === true) {
			let interval;

			let openChat = () => {
				let lo = document.getElementById('lo-messenger-frame');

				if (lo !== null && lo.style.display !== "none" && typeof window.LO !== "undefined" && typeof window.LO.messenger !== "undefined") {
					window.LO.messenger.open();

					clearInterval(interval);
				}
			}

			interval = setInterval(openChat, 250);
		}





		return {
			clientId: clientId,
			order: cookieData.order, // { orderId: null}
			productOffersSetId: cookieData.productOffersSetId,
			specialOffersSetId: cookieData.specialOffersSetId,
			specialOffers: cookieData.specialOffers, //{ specialOffersIds: [], discountCodesIds: [], discountCodes: [] }
			//questionnaires: questionnaires, // [{questionnaire (slug), questionnaireId, questionnaireTakeId}]
			language: language,
			currency: currency,
			testMode: cookieData.testMode,

			questionnaireId: queryStringParams.questionnaireId,
			questionnaireTakeId: queryStringParams.questionnaireTakeId,
			view: queryStringParams.view,
			question: queryStringParams.question,
			viewPage: queryStringParams.viewPage,
			reportType: queryStringParams.reportType,

			checkoutStep: queryStringParams.checkoutStep,
			paymentStatus: queryStringParams.paymentStatus,
			paymentProvider: queryStringParams.paymentProvider,
			orderCompletedId: queryStringParams.orderCompletedId,

			fbp: fbp,
			fbc: fbc,
		};
	}







	async encodeSha256(string) {
		const utf8 = new TextEncoder().encode(string);
		const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray
			.map((bytes) => bytes.toString(16).padStart(2, '0'))
			.join('');
		return hashHex;
	}





	// https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
	base64Encode = str =>
		btoa(
			encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, (match, p1) =>
				//String.fromCharCode("0x" + p1)
				String.fromCharCode(`0x${p1}`)
			)
		);

	base64Decode = str =>
		decodeURIComponent(
			atob(str)
				.split("")
				//.map(c => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
				.map(c => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`)
				.join("")
		);

	// https://tools.ietf.org/html/rfc7515#appendix-C
	base64UrlEncode = str =>
		this.base64Encode(str)
			.split("=")[0]
			.replace("+", "-")
			.replace("/", "_");

	base64UrlDecode = str =>
		this.base64Decode(
			str
				.replace("-", "+")
				.replace("_", "/")
				.padEnd(str.length + (4 - str.length % 4) % 4, "=")
		);





	// https://stackoverflow.com/a/2117523
	uuidv4() {
		return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
			(c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
		);
	}
}