import { html } from "htm/preact";
import { NavigationButton, NavigationButtonTypeEnum } from "./navigationButton.js";
import Markup from 'preact-markup';




export function QuestionnaireInformation(props) {
	//let message = props.questionnaire.welcomeMessage;
	return html`
		<div class=${"questionnaire-welcome-view" + (!this.props.touchDevice ? " non-touch" : "")}>
			<div class="questionnaire-welcome-title text-questionnaire-welcome-title">${props.questionnaire.welcomeTitle}</div>
			<div class="questionnaire-welcome-message text-questionnaire-welcome-message">
				<${Markup} type="html" markup=${props.questionnaire.welcomeMessage}/>
			</div>
			<div class="questionnaire-welcome-navigation-view text-questionnaire-welcome-navigation-view">
				<${NavigationButton} type=${NavigationButtonTypeEnum.BeginQuestionnaire} onNavigationButtonClick=${props.onQestionnaireBegin}/>
			</div>
		</div>
		`;
}