import { html } from "htm/preact";
import { useEffect, useState, useRef } from 'preact/hooks';
import { NavigationButton, NavigationButtonTypeEnum } from "./navigationButton.js";
import { NavigationStripe, NavigationPages } from "./navigationBar.js";
import $ from 'jquery';
import Markup from 'preact-markup';



export function QuestionnaireReport(props) {
	// Used only to rerender view in case herb info is fully loaded only after rendering second view is rendered (stays unchanged if herbs info is received during 1st view)
	// TODO Herb info components should be made as separate React elements and should receive info retrieval promise as property, this will make strucure and workflow clearer and allow to display already loaded herbs before all of them are loaded
	const [, herbsInfoLoaded] = useState(false);
	const herbInfo = useRef(null);
	// Required to have access to the current view page in the callback (after receiving herbs info from the server)
	const currentPage = useRef(null);
	if (currentPage.current != props.page) currentPage.current = props.page;


	useEffect(() => {
		if (typeof props.report === 'undefined' || props.report == null || typeof props.report.sections === 'undefined' || props.report.sections == null || !Array.isArray(props.report.sections)) return;
		let herbsSection = props.report.sections.find(section => section.type == 'Special' && section.slug == 'Herbs');
		if (typeof herbsSection === 'undefined') return;
		let herbs = typeof herbsSection.text !== 'undefined' && herbsSection.text != null ? herbsSection.text.split(/\r?\n/) : [];
		if (herbs.length == 0 || (herbs.length == 1 && herbs[0] == "")) return;

		let herbsSectionPage = 1;
		if (typeof herbsSection.page !== 'undefined' && herbsSection.page != null) {
			herbsSectionPage = herbsSection.page;
		}


		let ajaxPromises = herbs.map(element =>
			typeof element === 'undefined' || element == null || element == ""
				? null
				: $.ajax({
					url: '../herbindex/' + encodeURI(element) + '/' + encodeURI(this.props.language),
					type: 'GET',
					context: this
					})
		);

		$.when.apply($, ajaxPromises).then(function () {
			// $.when() tends to return array of request information from $.ajax() promise instead of data
			//https://stackoverflow.com/a/42099983
			herbInfo.current = Array.prototype.slice.call(arguments, 0).map((res) => Array.isArray(res) ? res[0] : res);
			if (currentPage.current == herbsSectionPage) herbsInfoLoaded(true);
		});
	}, [props.report]);


	useEffect(() => {
		$(".collapsible-header, .collapsible-title-text-single-line").click(() => {
			event.preventDefault();

			let el = $(event.target);
			let original_el = el;
			let i = 0;
			while (!el.hasClass("collapsible-header") && i < 5) {
				if (el.prev().hasClass("collapsible-header")) {
					el = el.prev();
					break;
                }
				el = el.parent();
				i++;
			}
			if (!el.hasClass("collapsible-header")) return;
			if (el.hasClass("collapsible-open") && (el == original_el || $.contains(el.get(0), original_el.get(0)))) {
				el.removeClass("collapsible-open");
				el.addClass("collapsible-closed");
				el.next().removeClass("collapsible-open");
				el.next().addClass("collapsible-closed");

				return;
			}
			if (el.hasClass("collapsible-open")) {
				return;
            }

			let domel = el.get(0);
			// jQuery.contains( container, descendant )
			let otherCollapsibleEls = $(".collapsible-header, .collapsible.collapsible-full, .collapsible-title-text-single-line, .collapsible.collapsible-preview, .collapsible.collapsible-fade");
			otherCollapsibleEls.each(function () {
				if (!$.contains(this, domel)) {
					let jqel = $(this);
					jqel.removeClass("collapsible-open");
					jqel.addClass("collapsible-closed");
                }
			});

			el.removeClass("collapsible-closed");
			el.addClass("collapsible-open");
			el.next().removeClass("collapsible-closed");
			el.next().addClass("collapsible-open");

			setTimeout(() => {
				$([document.documentElement, document.body]).animate({
					scrollTop: el.offset().top
				});
			}, 500);
		});
		$(".collapsible.collapsible-preview").click(() => {
			event.preventDefault();

			let el = $(event.target);
			//if (!el.hasClass("collapsible")) el = el.parents(".collapsible");
			el = el.closest(".collapsible");
			if (el.hasClass("collapsible-open")) {
				//el.removeClass("collapsible-open");
				//el.addClass("collapsible-closed");
				return;
			}

			let domel = el.get(0);
			// jQuery.contains( container, descendant )
			let otherCollapsibleEls = $(".collapsible-header, .collapsible.collapsible-full, .collapsible-title-text-single-line, .collapsible.collapsible-preview, .collapsible.collapsible-fade");
			otherCollapsibleEls.each(function () {
				if (!$.contains(this, domel)) {
					let jqel = $(this);
					jqel.removeClass("collapsible-open");
					jqel.addClass("collapsible-closed");
				}
			});

			el.removeClass("collapsible-closed");
			el.addClass("collapsible-open");

			setTimeout(() => {
				$([document.documentElement, document.body]).animate({
					scrollTop: el.offset().top
				});
			}, 500);
		});
		$(".collapsible.collapsible-fade").click(() => {
			event.preventDefault();

			let el = $(event.target);
			//if (!el.hasClass("collapsible")) el = el.parents(".collapsible");
			el = el.closest(".collapsible");
			if (el.hasClass("collapsible-open")) {
				//el.removeClass("collapsible-open");
				//el.addClass("collapsible-closed");
				return;
			}

			let domel = el.get(0);
			// jQuery.contains( container, descendant )
			let otherCollapsibleEls = $(".collapsible-header, .collapsible.collapsible-full, .collapsible-title-text-single-line, .collapsible.collapsible-preview, .collapsible.collapsible-fade");
			otherCollapsibleEls.each(function () {
				if (!$.contains(this, domel)) {
					let jqel = $(this);
					jqel.removeClass("collapsible-open");
					jqel.addClass("collapsible-closed");
				}
			});


			//$(".collapsible-header").removeClass("collapsible-open");
			//$(".collapsible.collapsible-full").removeClass("collapsible-open");
			//$(".collapsible.collapsible-preview").removeClass("collapsible-open");
			//$(".collapsible.collapsible-fade").removeClass("collapsible-open");
			//$(".collapsible-header").addClass("collapsible-closed");
			//$(".collapsible.collapsible-full").addClass("collapsible-closed");
			//$(".collapsible.collapsible-preview").addClass("collapsible-closed");
			//$(".collapsible.collapsible-fade").addClass("collapsible-closed");


			el.removeClass("collapsible-closed");
			el.addClass("collapsible-open");

			setTimeout(() => {
				$([document.documentElement, document.body]).animate({
					scrollTop: el.offset().top
				});
			}, 500);
		});

		return () => {
			$(".collapsible-header").off();
			$(".collapsible.collapsible-preview").off();
			$(".collapsible.collapsible-fade").off();
        }
	});



	let scrollToTop = () => $([document.documentElement, document.body]).animate({ scrollTop: 0 }, 250);
	//$('html, body')
	//$(window).scrollTop(0);

	//let hormoneLevelsSection = null;
	//let infoSections = [];
	//let herbsSection = null;
	
	// TODO typeof props.report === 'undefined' || props.report == null
	if (props.report.sections === 'undefined' || props.report.sections == null || !Array.isArray(props.report.sections)) props.report.sections = [];

	//hormoneLevelsSection = props.report.sections.find((section) => section.slug == "HormoneLevels");
	//infoSections = props.report.sections.filter((section) => section.type == "Info");
	//herbsSection = props.report.sections.find((section) => section.slug == "Herbs");


	//let numberOfPages = typeof herbsSection !== 'undefined' && herbsSection != null ? 2 : 1;
	let numberOfPages = 1;
	for (let section of props.report.sections) {
		if (typeof section.page !== 'undefined' && section.page != null && section.page > numberOfPages) {
			if (typeof section.pagesLength !== 'undefined' && section.pagesLength != null && section.pagesLength > 1) {
				numberOfPages = section.page + section.pagesLength - 1;
			} else {
				numberOfPages = section.page;
            }
        }
	}

	let pageNumber = 1;
	//if (props.page == 'first') pageNumber = 1;
	//else
	if (props.page == 'last') pageNumber = numberOfPages;
	// https://stackoverflow.com/a/14794066
	else if (!isNaN(props.page)) {
		let value = parseFloat(props.page);
		if ((value | 0) === value) pageNumber = value;
		if (pageNumber < 1) pageNumber = 1;
		if (pageNumber > numberOfPages) pageNumber = numberOfPages;
    }
	


	let reportPageBlocks = [];

	if (pageNumber == 1) {
		// Name, description
		reportPageBlocks.push(html`
			<div class="questionnaire-report-title text-questionnaire-report-title">
				${props.report.title}
			</div>
			<div class="questionnaire-report-description text-questionnaire-report-description">
				${props.report.description.split('\n').map((text, j) => html`<p key=${j}>${text}</p>`)}
			</div >
		`);
	}

	for (let section of props.report.sections) {
		let sectionFirstPageNumber = 1;
		let sectionLastPageNumber = 1;
		if (typeof section.page !== 'undefined' && section.page != null && section.page > 0) {
			sectionFirstPageNumber = section.page;
			if (typeof section.pagesLength !== 'undefined' && section.pagesLength != null && section.pagesLength > 1) {
				sectionLastPageNumber = sectionFirstPageNumber + section.pagesLength - 1;
			} else {
				sectionLastPageNumber = sectionFirstPageNumber;
			}
		}

		if (sectionLastPageNumber < pageNumber || sectionFirstPageNumber > pageNumber) continue;



		// Hormone levels
		if (section.slug == "HormoneLevels") {
			let levelDescriptionES = Math.abs(props.questionnaireResults.LevelES) == 3
				? "Very Low"
				: Math.abs(props.questionnaireResults.LevelES) == 2
					? "Low"
					: Math.abs(props.questionnaireResults.LevelES) == 1
						? "Decreased"
						: "Normal";
			let levelESClassName = Math.abs(props.questionnaireResults.LevelES) == 3
				? "questionnaire-report-hormone-level-extreme"
				: Math.abs(props.questionnaireResults.LevelES) == 2
					? "questionnaire-report-hormone-level-strong"
					: Math.abs(props.questionnaireResults.LevelES) == 1
						? "questionnaire-report-hormone-level-moderate"
						: "questionnaire-report-hormone-level-normal";

			let levelDescriptionPS = Math.abs(props.questionnaireResults.LevelPS) == 3
				? "Very Low"
				: Math.abs(props.questionnaireResults.LevelPS) == 2
					? "Low"
					: Math.abs(props.questionnaireResults.LevelPS) == 1
						? "Decreased"
						: "Normal";
			let levelPSClassName = Math.abs(props.questionnaireResults.LevelPS) == 3
				? "questionnaire-report-hormone-level-extreme"
				: Math.abs(props.questionnaireResults.LevelPS) == 2
					? "questionnaire-report-hormone-level-strong"
					: Math.abs(props.questionnaireResults.LevelPS) == 1
						? "questionnaire-report-hormone-level-moderate"
						: "questionnaire-report-hormone-level-normal";

			let levelDescriptionTS = props.questionnaireResults.LevelTS == 3
				? "Very High"
				: props.questionnaireResults.LevelTS == 2
					? "High"
					: props.questionnaireResults.LevelTS == 1
						? "Increased"
						: props.questionnaireResults.LevelTS == -1
							? "Decreased"
							: props.questionnaireResults.LevelTS == -2
								? "Low"
								: props.questionnaireResults.LevelTS == -3
									? "Very Low"
									: "Normal";

			let levelTSClassName = Math.abs(props.questionnaireResults.LevelTS) == 3
				? "questionnaire-report-hormone-level-extreme"
				: Math.abs(props.questionnaireResults.LevelTS) == 2
					? "questionnaire-report-hormone-level-strong"
					: Math.abs(props.questionnaireResults.LevelTS) == 1
						? "questionnaire-report-hormone-level-moderate"
						: "questionnaire-report-hormone-level-normal";


			let sections = new Array(4).fill(null);
			let testosteroneSections = new Array(7).fill(null);

			let oestrogenText = section.subSections.find((section) => section.slug == "HormoneLevels_Oestrogen");
			let progesteroneText = section.subSections.find((section) => section.slug == "HormoneLevels_Progesterone");
			let testosteroneText = section.subSections.find((section) => section.slug == "HormoneLevels_Testosterone");

			reportPageBlocks.push(html`
				${!section.title || section.title.length === 0
					? null
					: html`
						<div class="questionnaire-report-information-section-title text-questionnaire-report-information-section-title">
							<${Markup} type="html" markup=${section.title} />
						</div>
					`}
				${!section.description || section.description.length === 0
					? null
					: html`
						<div class="questionnaire-report-information-subsection-title text-questionnaire-report-information-subsection-title">
							<${Markup} type="html" markup=${section.description} />
						</div>
					`}

				<div class="questionnaire-report-hormone-levels-section-view">
					<div class="questionnaire-report-hormone-level-stripe-block-view">
						<div class="questionnaire-report-hormone-level-stripe-name text-questionnaire-report-hormone-level-stripe-name">
							Oestrogen
							${' '}
							<span class=${levelESClassName}>${levelDescriptionES}</span>
						</div>
						<div class="questionnaire-report-hormone-level-stripe-view-negative-zero">
							<div class="questionnaire-report-hormone-level-stripe-initial-section"></div>
							${sections.map((_, index) => html`
								${(sections.length - 1 - index) == (props.questionnaireResults.LevelES * -1)
										? html`<div key=${"stripe-selected-marker-"+index} class=${"questionnaire-report-hormone-level-stripe-selected-marker-section "  + levelESClassName}></div>`
										: html`<div key=${"stripe-marker-"+index} class="questionnaire-report-hormone-level-stripe-marker-section"></div>`
								}
								${index < sections.length - 1
										? html`<div key=${"stripe-join-"+index} class="questionnaire-report-hormone-level-stripe-join-section"></div>`
										: html``
									}`
								)}
							<div class="questionnaire-report-hormone-level-stripe-final-section"></div>
						</div>						
						<div class="questionnaire-report-hormone-level-stripe-description text-questionnaire-report-hormone-level-stripe-description"><${Markup} type="html" markup=${oestrogenText.text} /></div>
					</div>

					<div class="questionnaire-report-hormone-level-stripe-block-view">
						<div class="questionnaire-report-hormone-level-stripe-name text-questionnaire-report-hormone-level-stripe-name">
							Progesterone
							${' '}
							<span class=${levelPSClassName}>${levelDescriptionPS}</span>
						</div>
						<div class="questionnaire-report-hormone-level-stripe-view-negative-zero">
							<div class="questionnaire-report-hormone-level-stripe-initial-section"></div>
							${sections.map((_, index) => html`
								${(sections.length - 1 - index) == (props.questionnaireResults.LevelPS * -1)
									? html`<div key=${"stripe-selected-marker-" + index} class=${"questionnaire-report-hormone-level-stripe-selected-marker-section " + levelPSClassName}></div>`
									: html`<div key=${"stripe-marker-"+index} class="questionnaire-report-hormone-level-stripe-marker-section"></div>`
								}
								${index < sections.length - 1
									? html`<div key=${"stripe-join-"+index} class="questionnaire-report-hormone-level-stripe-join-section"></div>`
									: html``
								}`
							)}
							<div class="questionnaire-report-hormone-level-stripe-final-section"></div>
						</div>
						<div class="questionnaire-report-hormone-level-stripe-description text-questionnaire-report-hormone-level-stripe-description "><${Markup} type="html" markup=${progesteroneText.text} /></div>
					</div>

					<div class="questionnaire-report-hormone-level-stripe-block-view">
						<div class="questionnaire-report-hormone-level-stripe-name text-questionnaire-report-hormone-level-stripe-name">
							Testosterone
							${' '}
							<span class=${levelTSClassName}>${levelDescriptionTS}</span>							
						</div>
						<div class="questionnaire-report-hormone-level-stripe-view-negative-positive">
							<div class="questionnaire-report-hormone-level-stripe-initial-section"></div>
							${testosteroneSections.map((_, index) => html`
								${(-Math.floor(testosteroneSections.length / 2) + index) == props.questionnaireResults.LevelTS
									? html`<div key=${"stripe-selected-marker-" + index} class=${"questionnaire-report-hormone-level-stripe-selected-marker-section " + levelTSClassName}></div>`
									: html`<div key=${"stripe-marker-"+index} class="questionnaire-report-hormone-level-stripe-marker-section"></div>`
								}
								${index < testosteroneSections.length - 1
									? html`<div key=${"stripe-join-"+index} class="questionnaire-report-hormone-level-stripe-join-section"></div>`
									: html``
								}`
							)}
							<div class="questionnaire-report-hormone-level-stripe-final-section"></div>
						</div>
						<div class="questionnaire-report-hormone-level-stripe-description text-questionnaire-report-hormone-level-stripe-description"><${Markup} type="html" markup=${testosteroneText.text} /></div>
					</div>
				</div>
			`);
        }

		// Information
		// ${typeof section.text !== 'undefined' && section.text != null ? section.text.split('\n').map((text, j) => html`<${Markup} key=${j} type="html" markup=${text} />`) : null}
		if (section.type == "Info") {
			let collapsibleClasesHeader = "";
			let collapsibleClases = "";
			if (section.collapsible != "None") {
				collapsibleClases += " collapsible";
				collapsibleClasesHeader += "collapsible-header";
			}
			if (section.collapsible == "Title") collapsibleClases += " collapsible-full";
			if (section.collapsible == "TitleTextSingleLine") collapsibleClases += " collapsible-title-text-single-line";
			if (section.collapsible == "TitleTextReadMore") collapsibleClases += " collapsible-title-text-preview";
			if (section.collapsible == "TitleDescriptionSingleLine") collapsibleClases += " collapsible-title-description-single-line";
			if (section.collapsible == "TitleDescriptionReadMore") collapsibleClases += " collapsible-title-description-preview";
			if (section.collapsible == "DesccriptionReadMore") collapsibleClases += " collapsible-preview";
			if (section.collapsible == "ReadMore") collapsibleClases += " collapsible-preview";
			if (section.collapsible == "Fading") collapsibleClases += " collapsible-fade";

			if (section.collapsed) {
				collapsibleClases += " collapsible-closed";
				collapsibleClasesHeader += " collapsible-closed";
			}
			if (section.collapsible != "None" && !section.collapsed) {
				collapsibleClases += " collapsible-open";
				collapsibleClasesHeader += " collapsible-open";
			}

			let introBlocks = [];
			let mainBlocks = [];
			let conclusionBlocks = [];
			let moreBlocks = [];

			if (section.subSections != null && section.subSections.length > 0) {
				section.subSections.sort(function (a, b) {
					let a_introduction = (typeof a.parameters !== 'undefined' && a.parameters != null && a.parameters.includes("introduction"));
					let b_introduction = (typeof b.parameters !== 'undefined' && b.parameters != null && b.parameters.includes("introduction"));

					let a_significance = (typeof a.significance !== 'undefined' && a.significance != null) ? a.significance : -1;
					let b_significance = (typeof b.significance !== 'undefined' && b.significance != null) ? b.significance : -1;

					let a_order = (typeof a.order !== 'undefined' && a.order != null) ? a.order : -1;
					let b_order = (typeof b.order !== 'undefined' && b.order != null) ? b.order : -1;

					let a_conclusion = (typeof a.parameters !== 'undefined' && a.parameters != null && a.parameters.includes("conclusion"));
					let b_conclusion = (typeof b.parameters !== 'undefined' && b.parameters != null && b.parameters.includes("conclusion"));

					let a_hidden = (typeof a.hidden !== 'undefined' && a.hidden == true);
					let b_hidden = (typeof b.hidden !== 'undefined' && b.hidden == true);


					if (a_introduction && !b_introduction) return -1;
					if (!a_introduction && b_introduction) return 1;
					if (a_introduction && b_introduction) {
						return a_significance != b_significance
							? b_significance - a_significance
							: a_order - b_order;
					}

					if (!a_conclusion && !a_hidden && (b_conclusion || b_hidden)) return -1;
					if ((a_conclusion || a_hidden) && !b_conclusion && !b_hidden) return 1;
					if (!a_conclusion && !a_hidden && !b_conclusion && !b_hidden){
						return a_significance != b_significance
							? b_significance - a_significance
							: a_order - b_order;
					}

					if (a_conclusion && !b_conclusion) return -1;
					if (!a_conclusion && b_conclusion) return 1;
					if (a_conclusion && b_conclusion) {
						return a_significance != b_significance
							? b_significance - a_significance
							: a_order - b_order;
					}


					if (a_hidden && !b_hidden) return -1;
					if (!a_hidden && b_hidden) return 1;
					if (a_hidden && b_hidden) {
						return a_significance != b_significance
							? b_significance - a_significance
							: a_order - b_order;
					}							
				});

				let unspecifiedSubsectionPage = sectionFirstPageNumber;
				section.subSections.forEach((subsection, i) => {
					let subsectionPage = unspecifiedSubsectionPage;
					if (typeof subsection.page !== 'undefined' && subsection.page != null && subsection.page > 0) {
						subsectionPage = sectionFirstPageNumber + subsection.page - 1;
						if (subsectionPage > unspecifiedSubsectionPage) unspecifiedSubsectionPage = subsectionPage;
					}

					if (subsectionPage != pageNumber) return;

					let blocks = [];

					let collapsibleClasesSub = "";
					let collapsibleClasesHeaderSub = "";
					if (subsection.collapsible != "None") {
						collapsibleClasesSub += " collapsible";
						collapsibleClasesHeaderSub += "collapsible-header";
					}

					if (subsection.hidden || subsection.collapsible == "Title") collapsibleClasesSub += " collapsible-full";
					else if (subsection.collapsible == "TitleTextSingleLine") collapsibleClasesSub += " collapsible-title-text-single-line";
					else if (subsection.collapsible == "TitleTextReadMore") collapsibleClasesSub += " collapsible-title-text-preview";
					else if (subsection.collapsible == "TitleDescriptionSingleLine") collapsibleClasesSub += " collapsible-title-description-single-line";
					else if (subsection.collapsible == "TitleDescriptionReadMore") collapsibleClasesSub += " collapsible-title-description-preview";
					else if (subsection.collapsible == "DesccriptionReadMore") collapsibleClasesSub += " collapsible-preview";
					else if (subsection.collapsible == "ReadMore") collapsibleClasesSub += " collapsible-preview";
					else if (subsection.collapsible == "Fading") collapsibleClasesSub += " collapsible-fade";

					if (subsection.collapsed) {
						collapsibleClasesSub += " collapsible-closed";
						collapsibleClasesHeaderSub += " collapsible-closed";
					}
					if (subsection.collapsible != "None" && !subsection.collapsed) {
						collapsibleClasesSub += " collapsible-open";
						collapsibleClasesHeaderSub += " collapsible-open";
					}
					if (subsection.collapsible == "Title" || subsection.collapsible == "TitleTextSingleLine" || subsection.collapsible == "TitleDescriptionSingleLine") {
						blocks.push(html`
									<button class=${collapsibleClasesHeaderSub}><i class="collapsible-chevron"></i><${Markup} type="html" markup=${subsection.title != null ? subsection.title : ""} /></button>
								`);
					}
					else if (subsection.title != null && subsection.title != "") {
						blocks.push(html`
									<div class="questionnaire-report-information-subsection-title text-questionnaire-report-information-subsection-title">
										<${Markup} type="html" markup=${subsection.title} />
									</div>
								`);
					}

					//if (subsection.collapsible == "Title" || subsection.collapsible == "TitleReadMore" || subsection.collapsible == "DesccriptionReadMore" || subsection.collapsible == "ReadMore" || subsection.collapsible == "Fading") {
					//	blocks.push(html`<div class=${collapsibleClasesSub}>`);
					//}

					if (subsection.text != null && subsection.text != "") {
						blocks.push(html`
								<div class=${collapsibleClasesSub}>
									<div class="questionnaire-report-information-section-text text-questionnaire-report-information-section-text">
										<${Markup} type="html" markup=${subsection.text} />
									</div>
								</div>
							`);
					}

					//if (subsection.collapsible == "Title" || subsection.collapsible == "TitleReadMore" || subsection.collapsible == "DesccriptionReadMore" || subsection.collapsible == "ReadMore" || subsection.collapsible == "Fading") {
					//	blocks.push(html`</div>`);
					//}

					if (typeof subsection.parameters !== 'undefined' && subsection.parameters != null && subsection.parameters.includes("introduction")) {
						introBlocks.push(blocks);
					} else if (typeof subsection.parameters !== 'undefined' && subsection.parameters != null && subsection.parameters.includes("conclusion")) {
						conclusionBlocks.push(blocks);
					} else if (typeof subsection.hidden !== 'undefined' && subsection.hidden == true) {
						//if (moreBlocks.length == 0) {
						//	moreBlocks.push(html`<div class="questionnaire-report-information-section-title text-questionnaire-report-information-section-title" style="margin-top: 48px;">Your other symptoms</div>`);
                        //}
						moreBlocks.push(blocks);
					} else {
						mainBlocks.push(blocks);
					}
				})
			}


			/*
									${moreBlocks.length > 0
										? html`<button class="collapsible-header collapsible-closed"><i class="collapsible-chevron"></i><div>More symptoms</div></button>
												<div class="collapsible collapsible-full collapsible-closed">
													${moreBlocks}
												</div>
										`
										: null
									}
			*/
			// <div class="questionnaire-report-information-view text-questionnaire-report-information-view">
			//     <div key=${i} class="questionnaire-report-information-section-view">
			reportPageBlocks.push(html`
							<div class="questionnaire-report-information-section-view">
								${section.collapsible == "Title" || section.collapsible == "TitleTextSingleLine" || section.collapsible == "TitleDescriptionSingleLine"
									? html`
										<button class=${collapsibleClasesHeader}><i class="collapsible-chevron"></i><${Markup} type="html" markup=${section.title != null ? section.title : ""} /></button>
									`
									: html`
										<div class="questionnaire-report-information-section-title text-questionnaire-report-information-section-title">
											<${Markup} type="html" markup=${section.title != null ? section.title : ""} />
										</div>
									`
								}

								<div class=${collapsibleClases}>
									${section.text != null && section.text != ""
										? html`
											<div class="questionnaire-report-information-section-text text-questionnaire-report-information-section-text">
												<${Markup} type="html" markup=${section.text} />
											</div>
										`
										: null
									}

									${introBlocks}
									${mainBlocks}
									${conclusionBlocks}

									${moreBlocks}
								</div>
							</div>
						`);
        }


		// Hero herbs
		if (section.slug == "Herbs") {
			reportPageBlocks.push(html`
				<div class="questionnaire-report-herbs-section-view">
					${!section.title || section.title.length === 0
						? null
						: html`
							<div class="questionnaire-report-herbs-section-title text-questionnaire-report-herbs-section-title">
								<${Markup} type="html" markup=${section.title} />
							</div>
						`}
					${!section.description || section.description.length === 0
						? null
						: html`
							<div class="questionnaire-report-herbs-section-description text-questionnaire-report-herbs-section-description">
								<${Markup} type="html" markup=${section.description} />
							</div>
						`}

					${herbInfo.current != null
						? herbInfo.current.map((herb, hi) =>
							typeof herb === 'undefined'
								? null
								: html`
										<div key="${hi}" class="questionnaire-report-herbs-section-herb-view">
											<div class="questionnaire-report-herbs-section-herb-image-view">
												<img class="questionnaire-report-herbs-section-herb-image" src=${"/herbimages/400/" + herb.latinName + ".jpg"} alt=${herb.latinName} />
											</div>
											<div class="questionnaire-report-herbs-section-herb-name text-questionnaire-report-herbs-section-herb-name">${herb.name}</div>
											<div class="questionnaire-report-herbs-section-herb-category text-questionnaire-report-herbs-section-herb-category">${herb.category}</div>
											<div class="questionnaire-report-herbs-section-herb-summary text-questionnaire-report-herbs-section-herb-summary">${herb.summary}</div>
										</div>
									`
							)
						: null
					}

					${section.subSections !== null && section.subSections.length > 0
						? html`
							<div class="questionnaire-report-information-section-view">
								${section.subSections.map(subsection => html`
									${typeof subsection.title === 'string' && subsection.title.length === 0
										? html`
											<div class="questionnaire-report-information-section-title text-questionnaire-report-information-section-title">
												<${Markup} type="html" markup=${subsection.title} />
											</div>
										`
										: null
									}
									<div class="questionnaire-report-information-section-text text-questionnaire-report-information-section-text">
										<${Markup} type="html" markup=${subsection.text} />
									</div>
								`)}
							</div>
						`
						: null
					}
				</div>
			`);
		}
	}


	//${
	//	props.reportType.toLowerCase() == 'full'
	//	? html`<${NavigationButton} type=${NavigationButtonTypeEnum.ReportBackToFirstQuestion} onNavigationButtonClick=${props.onReturnToFirstQuestion} />`
	//	: null
	//}

	if (pageNumber == 1) {
		reportPageBlocks.push(html`
			<div class="questionnaire-report-navigation-view">
				<${NavigationButton} type=${NavigationButtonTypeEnum.ReportBackToLastQuestion} onNavigationButtonClick=${props.onReturnToLastQuestion} />
				${numberOfPages > 1
					? html`<${NavigationButton} type=${NavigationButtonTypeEnum.ReportNextSection} onNavigationButtonClick=${() => { /*setPage(+1);*/ props.onChangePage(pageNumber + 1); scrollToTop(); }} />`
					: html`<${NavigationButton} type=${NavigationButtonTypeEnum.ReportComplete} onNavigationButtonClick=${() => { }} url=${props.reportFinishedUrl} />`
				}
			</div>
		`);
	}
	if (pageNumber > 1 && pageNumber < numberOfPages) {
		reportPageBlocks.push(html`
			<div class="questionnaire-report-navigation-view text-questionnaire-report-navigation-view">
				<${NavigationButton} type=${NavigationButtonTypeEnum.ReportPreviousSection} onNavigationButtonClick=${() => { /*setPage(-1);*/ props.onChangePage(pageNumber - 1); scrollToTop(); }} />
				<${NavigationButton} type=${NavigationButtonTypeEnum.ReportNextSection} onNavigationButtonClick=${() => { /*setPage(+1);*/ props.onChangePage(pageNumber + 1); scrollToTop(); }} />
			</div>
		`);
	}
	if (pageNumber > 1 && pageNumber == numberOfPages) {
		reportPageBlocks.push(html`
			<div class="questionnaire-report-navigation-view text-questionnaire-report-navigation-view">
				<${NavigationButton} type=${NavigationButtonTypeEnum.ReportPreviousSection} onNavigationButtonClick=${() => { /*setPage(-1);*/ props.onChangePage(pageNumber - 1); scrollToTop(); }} />
				<${NavigationButton} type=${NavigationButtonTypeEnum.ReportComplete} onNavigationButtonClick=${() => { }} url=${props.reportFinishedUrl} />
			</div>
		`);
	}


	// https://stackoverflow.com/a/7220510
	const jsonStringifyAndSyntaxHighlight = (json) => {
		if (typeof json != 'string') {
			json = JSON.stringify(json, null, 4);
		}
		json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
		return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
			let cls = 'number';
			if (/^"/.test(match)) {
				if (/:$/.test(match)) {
					cls = 'key';
				} else {
					cls = 'string';
				}
			} else if (/true|false/.test(match)) {
				cls = 'boolean';
			} else if (/null/.test(match)) {
				cls = 'null';
			}
			return '<span class="' + cls + '">' + match + '</span>';
		});
	}


	return html`
		<div ${"questionnaire-report-view" + (!this.props.touchDevice ? " non-touch" : "")}>
			${reportPageBlocks}
		</div>
		${numberOfPages > 1
			? html`
				<${NavigationStripe} NumberOfSections=${numberOfPages} ActiveSectionOrder=${pageNumber}/>
				<${NavigationPages} NumberOfPages=${numberOfPages} CurrentPage=${pageNumber}/>
			`
			: null}

		<!-- Debug information -->
		${props.debug
			? html`
				<div style="display: block" class="questionnaire-debug-view">
					<p>
						<div>ClientId: ${props.clientId}</div>
						<div>QuestionnaireId: ${props.questionnaireId}</div>
						<div>QuestionnaireTakeId: ${props.questionnaireTakeId}</div>
					</p>

					<${Markup} type="html" trim=${false} markup=${`<style>pre .string { color: green; } pre .number { color: darkorange; } pre .boolean { color: blue; } pre .null { color: magenta; } pre .key { color: red; }</style><pre style="outline: 1px solid #ccc; padding: 5px; margin: 5px;">${jsonStringifyAndSyntaxHighlight(props.questionnaireResults, null, 4)}</pre>`} />

					<p>
						<div>Score ES: ${props.questionnaireResults.ScoreES}</div>
						<div>Score PS: ${props.questionnaireResults.ScorePS}</div>
						<div>Score TS: ${props.questionnaireResults.ScoreTS}</div>
						<div>Score AS: ${props.questionnaireResults.ScoreAS}</div>
					</p>
					<p>
						<div>Result ES: ${props.questionnaireResults.ResultESR}</div>
						<div>Result PS: ${props.questionnaireResults.ResultPSR}</div>
						<div>Result TS: ${props.questionnaireResults.ResultTSR}</div>
						<div>Result AS: ${props.questionnaireResults.ResultASR}</div>
					</p>				
					<p>
						<div>Level ES: ${props.questionnaireResults.LevelES}</div>
						<div>Level PS: ${props.questionnaireResults.LevelPS}</div>
						<div>Level TS: ${props.questionnaireResults.LevelTS}</div>
					</p>
					<p>
						<div>TS/AS Conflict: ${props.questionnaireResults.TsAsConflict != 0 ? "Yes" : "No"}</div>
						<div>TS/AS Conflict Resolved: ${props.questionnaireResults.TsAsConflictResolved != 0 ? "Yes" : "No"}</div>
						<div>TS High Level Conflict: ${props.questionnaireResults.LevelTSHConflict != 0 ? props.questionnaireResults.LevelTSHConflict : "No"}</div>
						<div>TS Low Level Conflict: ${props.questionnaireResults.LevelTSLConflict != 0 ? props.questionnaireResults.LevelTSLConflict : "No"}</div>
					</p>
					<p><div>SeverelyYes answer count per section</div>
						<div>A: ${props.questionnaireResults.SevereYesA}</div>
						<div>B: ${props.questionnaireResults.SevereYesB}</div>
						<div>C: ${props.questionnaireResults.SevereYesC}</div>
						<div>D: ${props.questionnaireResults.SevereYesD}</div>
						<div>E: ${props.questionnaireResults.SevereYesE}</div>
						<div>F: ${props.questionnaireResults.SevereYesF}</div>
						<div>G: ${props.questionnaireResults.SevereYesG}</div>
					</p>
					<p><div>Yes answer count per section</div>
						<div>A: ${props.questionnaireResults.YesA}</div>
						<div>B: ${props.questionnaireResults.YesB}</div>
						<div>C: ${props.questionnaireResults.YesC}</div>
						<div>D: ${props.questionnaireResults.YesD}</div>
						<div>E: ${props.questionnaireResults.YesE}</div>
						<div>F: ${props.questionnaireResults.YesF}</div>
						<div>G: ${props.questionnaireResults.YesG}</div>
					</p>				
					<p>Type: ${props.questionnaireResults.Type}</p>
				</div>
			`
			: null}
	`;
}