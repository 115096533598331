import { render } from "preact";
import { html } from "htm/preact";
import Questionnaire from "./components/questionnaire.js";

import Styles from './styles.css'


// Set in wordpress
//window.orderViewConfiguration = {
//	currentProduct: "HerbMixTest",
//}



let prepareQuestionnaireWidgetInterval = null;
let prepareQuestionnaireWidget = () => {
	let questionaireWidgetPlaceholder = document.getElementsByClassName("questionnaire");
	if (questionaireWidgetPlaceholder.length > 0) {
		let styleElement = document.head.appendChild(document.createElement("style"));
		styleElement.innerHTML = Styles;


		let questionnaireSlug = questionaireWidgetPlaceholder[0].dataset.questionnaire;
		render(html`<${Questionnaire} questionnaireSlug=${questionnaireSlug} language="eng" nextOnChoiceSelect=${true} additionalNextButtonNotChoice=${true} debug=${true} allowMandatoryQuestionSkipping=${false} />`, questionaireWidgetPlaceholder[0]);


		if (prepareQuestionnaireWidgetInterval !== null) {
			clearInterval(prepareQuestionnaireWidgetInterval);
		}
	} else if (prepareQuestionnaireWidgetInterval === null) {
		prepareQuestionnaireWidgetInterval = setInterval(prepareQuestionnaireWidget, 50);
	}
}
prepareQuestionnaireWidget();
