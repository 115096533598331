//import { html, render } from 'https://unpkg.com/htm/preact/standalone.module.js'
import { h, Component } from "preact";
import { html } from "htm/preact";
import $ from 'jquery';
import Markup from 'preact-markup';


// Subquestions and navigation logic - under questionnaire; question - single question

export default class Question extends Component {
	constructor(props) {
		super(props);

		this.currentQuestionId = this.props.question.id;
		this.inputReferences = [];
		this.containerReference = null;
		this.answered = false;
		this.answerProcessed = [];
		this.answerSaved = [];

		this.singleChoiceButtonsLocked = false;

		this.textAreaRegularSaveTimeout = null;

		this.iPhoneRerenderBugHelperCounter = 0;

		//this.props.question.answersConfigurations.forEach((ac) => this.inputReferences[ac.id] = createRef());

		//let answerState = this.setSavedAnswer();
		//let answered = false; //this.checkQuestionAnswered(true, answerState);
		//this.answeredChecked = false;

		//this.state = {
		//	answerState: answerState,
		//	answered: answered
		//};


		this.state = {
			answerState: this.setSavedAnswer(),
			answered: false,
			touchDevice: false
		};
	}



	// Wait untill answer processing is completed 
	saveAnswers(callback) {
		this.ensureAnswersProcessed(function () {
			this.props.question.answersConfigurations.forEach((ac) => {
			//this.answerSaved.forEach((saved, index) => {
				if (this.answerSaved[ac.id] === false) {
					this.props.onAnswerChanged(this.props.question, this.state.answerState[ac.id], ac.id);
					this.answerSaved[ac.id] = true;
				}
			});
			if (this.textAreaRegularSaveTimeout != null) {
				clearTimeout(this.textAreaRegularSaveTimeout);
				this.textAreaRegularSaveTimeout = null;
            }
			callback();
		}.bind(this));
    }
	//


	// Just wait untill all timeout callbacks are completed
	ensureAnswersProcessed(callback) {
		//if (this.answerProcessed.every(as => as)) callback();
		if (this.props.question.answersConfigurations.every(ac => typeof this.answerProcessed[ac.id] === 'undefined' || this.answerProcessed[ac.id] === true)) callback();
		else window.setTimeout(this.ensureAnswersProcessed.bind(this, callback), 100);
	}


	reportValidity() {
		this.props.question.answersConfigurations.forEach((ac) => {
			if ((ac.answerType == "String" || ac.answerType == "Text" || ac.answerType == "NumericInt" || ac.answerType == "NumericFloat" || ac.answerType == "Date")
				&& typeof this.inputReferences !== 'undefined' && typeof this.inputReferences[ac.id] !== 'undefined') {
				this.inputReferences[ac.id].reportValidity();
            }
		});
	}



	setSavedAnswer() {
		let answerState = {};
		this.props.question.answersConfigurations.forEach(ac => {
			let optionsChecked = [];
			let boolValue = false;
			let textValue = "";
			let numericValue = null;
			let dateValue = null;

			if (ac.answer != null) {
				if (ac.answer.answerOptions != null && ac.answer.answerOptions.length > 0) {
					optionsChecked = ac.answer.answerOptions;
				}
				if (ac.answer.valueBool != null) {
					boolValue = ac.answer.valueBool;
				}
				if (ac.answer.text != null) {
					textValue = ac.answer.text;
				}
				if (ac.answer.valueInt != null) {
					numericValue = ac.answer.valueInt;
				}
				if (ac.answer.valueFloat != null) {
					numericValue = ac.answer.valueFloat;
				}
				if (ac.answer.valueDateTime != null) {
					dateValue = Date.parse(ac.answer.valueDateTime);
				}
			}

			answerState[ac.id] = {
				optionsChecked: optionsChecked,
				optionsCheckedOrder: [...optionsChecked],
				boolValue: boolValue,
				textValue: textValue,
				numericValue: numericValue,
				dateValue: dateValue,
			}
		});
		return answerState;
    }



	checkQuestionAnswered(firstCheck = false, answerStateParameter = null) {
		let answered;
		let validationAvailable = false;

		if (answerStateParameter == null && (this.state == undefined || this.state.answerState == undefined)) {
			answered = false;
			validationAvailable = false;
		}
		else {
			let answerState = answerStateParameter == null ? this.state.answerState : answerStateParameter;
			answered = !this.props.question.answersConfigurations.some(ac => {
				if (ac.mandatory) {
					let answeredAC =
						ac.answerType == "None"
						|| (answerState[ac.id] != undefined &&
							(((ac.answerType == "Choice" || ac.answerType == "MultiChoice") && answerState[ac.id].optionsChecked != null && answerState[ac.id].optionsChecked.length > 0)
							|| ((ac.answerType == "SingleChoice") && answerState[ac.id].boolValue)
							|| ((ac.answerType == "String" || ac.answerType == "Text") && answerState[ac.id].textValue != "" && typeof this.inputReferences !== 'undefined' && typeof this.inputReferences[ac.id] !== 'undefined' && this.inputReferences[ac.id].checkValidity())
							|| ((ac.answerType == "NumericInt" || ac.answerType == "NumericFloat") && answerState[ac.id].numericValue != null && answerState[ac.id].numericValue != undefined && !isNaN(answerState[ac.id].numericValue) && typeof this.inputReferences !== 'undefined' && typeof this.inputReferences[ac.id] !== 'undefined' && this.inputReferences[ac.id].checkValidity())
							|| (ac.answerType == "Date" && answerState[ac.id].dateValue != null && answerState[ac.id].dateValue != undefined && !isNaN(answerState[ac.id].dateValue)) && typeof this.inputReferences !== 'undefined' && typeof this.inputReferences[ac.id] !== 'undefined' && this.inputReferences[ac.id].checkValidity())
						);

					if (!answeredAC) {
						return true;
					}
				}
				else {
					let answeredAC =
						ac.answerType == "None" || ac.answerType == "Choice" || ac.answerType == "MultiChoice" || ac.answerType == "SingleChoice"
						|| (answerState[ac.id] != undefined &&
							(((ac.answerType == "String" || ac.answerType == "Text") && typeof this.inputReferences !== 'undefined' && typeof this.inputReferences[ac.id] !== 'undefined' && this.inputReferences[ac.id].checkValidity())
							|| ((ac.answerType == "NumericInt" || ac.answerType == "NumericFloat") && (!isNaN(answerState[ac.id].numericValue)) && typeof this.inputReferences !== 'undefined' && typeof this.inputReferences[ac.id] !== 'undefined' && this.inputReferences[ac.id].checkValidity())
							|| (ac.answerType == "Date" && typeof this.inputReferences !== 'undefined' && typeof this.inputReferences[ac.id] !== 'undefined' && this.inputReferences[ac.id].checkValidity())));

					if (!answeredAC) {
						return true;
					}

                }
			}, this);

			validationAvailable = this.props.question.answersConfigurations.some(ac => ac.answerType == "String" || ac.answerType == "Text" || ac.answerType == "NumericInt" || ac.answerType == "NumericFloat" || ac.answerType == "Date");
		}


		if (firstCheck || answered != this.answered) {
			this.props.onQuestionAnsweredChanged(answered, validationAvailable);
		}

		this.answered = answered;

		return answered;
	}


	// https://stackoverflow.com/a/1909508
	//delay(fn, ms) {
	//	let timer = 0
	//	return function (...args) {
	//		clearTimeout(timer)
	//		timer = setTimeout(fn.bind(this, ...args), ms || 0)
	//	}
	//}
	//delay(callback, ms) {
	//	var timer = 0;
	//	return function () {
	//		console.log('Log2.0!');
	//		var context = this, args = arguments;
	//		clearTimeout(timer);
	//		timer = setTimeout(function () {
	//			callback.apply(context, args);
	//		}, ms || 0);
	//	};
	//}

	delay(callback, id, time) {
		if (typeof this.delays == "undefined") { this.delays = {}; }
		this.delays[id] = Date.now();
		let t = this.delays[id];
		setTimeout(function () {
			if (this.delays[id] != t) return;

			this.delays[id] = "";
			callback();
		}.bind(this), time || 500);
	}


	onOptionSelected(e, optionId, answersConfigurationId) {
		if (e != undefined && e != null) e.preventDefault();

		if (this.props.singleChoiceLoadNextQuestion && this.singleChoiceButtonsLocked) return;
		if (this.props.singleChoiceLoadNextQuestion) this.singleChoiceButtonsLocked = true;

		let answerChanged = false;
		let optionsCheckedNew;

		if (!this.state.answerState[answersConfigurationId].optionsChecked.includes(optionId)) {
			optionsCheckedNew = [optionId];
			answerChanged = true;
		}

		if (answerChanged) {
			// TODO should here and other similar appearances object be created immutable. Isn't it?
			let answerState = this.state.answerState;
			answerState[answersConfigurationId].optionsChecked = optionsCheckedNew;
			this.setState({
				answerState: answerState
			}/*, this.checkQuestionAnswered*/);

			this.props.onAnswerChanged(this.props.question, answerState[answersConfigurationId], answersConfigurationId);
			this.checkQuestionAnswered(false, answerState); // TODO check only changed answer (here and other value type change events)
		}
		if (!answerChanged && this.props.singleChoiceLoadNextQuestion) {
			this.props.onAnswerChanged(this.props.question, this.state.answerState[answersConfigurationId], answersConfigurationId);

			// changing and straight after saving this answer does not change answerSaved state
		}
	}


	// TODO implement minimal answer number
	onMultiOptionSelected(e, optionId, answersConfigurationId) {
		if (e != undefined && e != null) e.preventDefault();

		let optionsCheckedNew;
		let optionsCheckedOrderNew;

		let index = this.state.answerState[answersConfigurationId].optionsChecked.indexOf(optionId);
		if (index > -1) {
			//optionsCheckedNew = this.state.answerState[answersConfigurationId].optionsChecked.splice(index, 1).slice();
			optionsCheckedNew = [...this.state.answerState[answersConfigurationId].optionsChecked.slice(0, index), ...this.state.answerState[answersConfigurationId].optionsChecked.slice(index + 1)];

			let checkedOrderIndex = this.state.answerState[answersConfigurationId].optionsCheckedOrder.indexOf(optionId);
			if (checkedOrderIndex > -1) {
				optionsCheckedOrderNew = [...this.state.answerState[answersConfigurationId].optionsCheckedOrder.slice(0, checkedOrderIndex), ...this.state.answerState[answersConfigurationId].optionsCheckedOrder.slice(checkedOrderIndex + 1)];
			}
		} else {
			optionsCheckedNew = this.state.answerState[answersConfigurationId].optionsChecked.concat([optionId]);
			optionsCheckedOrderNew = [...this.state.answerState[answersConfigurationId].optionsCheckedOrder, optionId];

			let answersConfiguration = this.props.question.answersConfigurations.find(ac => ac.id == answersConfigurationId);

			if (typeof answersConfiguration !== 'undefined' && typeof answersConfiguration.maxAnswerNumber !== 'undefined' && answersConfiguration.maxAnswerNumber != null && answersConfiguration.maxAnswerNumber > 0 && optionsCheckedOrderNew.length > answersConfiguration.maxAnswerNumber) {
				let uncheckOptionId = optionsCheckedOrderNew.shift();
				index = optionsCheckedNew.indexOf(uncheckOptionId);
				optionsCheckedNew.splice(index, 1);
            }
		}


		let answerState = this.state.answerState;
		answerState[answersConfigurationId].optionsChecked = optionsCheckedNew;
		answerState[answersConfigurationId].optionsCheckedOrder = optionsCheckedOrderNew;
		this.setState({
			answerState: answerState,

		}/*, this.checkQuestionAnswered*/);

		this.props.onAnswerChanged(this.props.question, answerState[answersConfigurationId], answersConfigurationId);
		this.checkQuestionAnswered(false, answerState);

		// changing and straight after saving this answer does not change answerSaved state
	}


	onSingleOptionSelected(e, answersConfigurationId) {
		if (e != undefined && e != null) e.preventDefault();

		let answerState = this.state.answerState;
		answerState[answersConfigurationId].boolValue = !this.state.answerState[answersConfigurationId].boolValue

		this.setState({
			answerState: answerState,
		}/*, this.checkQuestionAnswered*/);
		
		this.props.onAnswerChanged(this.props.question, answerState[answersConfigurationId], answersConfigurationId);
		this.checkQuestionAnswered(false, answerState);
	}



	onStringInput(e, answersConfigurationId) {
		e.preventDefault();

		if (e.target.value != this.state.answerState[answersConfigurationId].textValue) {
			let answerState = this.state.answerState;
			answerState[answersConfigurationId].textValue = e.target.value;
			this.setState({
				answerState: answerState
			}/*, this.checkQuestionAnswered*/);


			//if (this.answerProcessed[answersConfigurationId] !== true) {
				this.answerProcessed[answersConfigurationId] = false;
			//}
			this.answerSaved[answersConfigurationId] = false;
			this.props.onAnswersSavedChanged(false);


			// Check value not faster than 500ms after last change
			this.delay(function () {
				if (!this.answerProcessed[answersConfigurationId]) {
					//this.props.onAnswerChanged(this.props.question, answerState[answersConfigurationId], answersConfigurationId);
					this.checkQuestionAnswered(false, answerState);
					this.answerProcessed[answersConfigurationId] = true;
				}
			}.bind(this), answersConfigurationId, 750)
		}
	}



	onTextInput(e, answersConfigurationId) {
		e.preventDefault();

		if (e.target.value != this.state.answerState[answersConfigurationId].textValue) {
			let answerState = this.state.answerState;
			answerState[answersConfigurationId].textValue = e.target.value;
			this.setState({
				answerState: answerState
			}/*, this.checkQuestionAnswered*/);



			//if (this.answerProcessed[answersConfigurationId] !== true) {
				this.answerProcessed[answersConfigurationId] = false;
			//}
			this.answerSaved[answersConfigurationId] = false;
			this.props.onAnswersSavedChanged(false);

			// Save value not faster than 1000ms after last change
			this.delay(function (e) {
				if (!this.answerProcessed[answersConfigurationId]) {
					this.props.onAnswerChanged(this.props.question, answerState[answersConfigurationId], answersConfigurationId);
					this.checkQuestionAnswered(false, answerState);
					this.answerProcessed[answersConfigurationId] = true;
					this.ensureAnswersProcessed(() => this.props.onAnswersSavedChanged(this.answerSaved.every(as => as)));
				}
			}.bind(this), answersConfigurationId, 1000)


			// Save at least once per 15 seconds
			if (this.textAreaRegularSaveTimeout == null) {
				this.textAreaRegularSaveTimeout = setTimeout(function (e) {
					if (!this.answerProcessed[answersConfigurationId]) {
						this.props.onAnswerChanged(this.props.question, answerState[answersConfigurationId], answersConfigurationId);
						this.checkQuestionAnswered(false, answerState);
						this.answerProcessed[answersConfigurationId] = true;
						this.ensureAnswersProcessed(() => this.props.onAnswersSavedChanged(this.answerSaved.every(as => as)));
					}

					this.textAreaRegularSaveTimeout = null;
				}.bind(this), 15000);
			}
		}
	}



	onNumericInput(e, answersConfigurationId) {
		e.preventDefault();

		let answerType = this.props.question.answersConfigurations.find(ac => ac.id == answersConfigurationId).answerType;

		if ((e.target.value == "" && this.state.answerState[answersConfigurationId].numericValue != "" && this.state.answerState[answersConfigurationId].numericValue != null)
			|| (answerType == "NumericInt" && parseInt(e.target.value, 10) != this.state.answerState[answersConfigurationId].numericValue)
			|| (answerType && parseFloat(e.target.value) != this.state.answerState[answersConfigurationId].numericValue)) {

			let answerState = this.state.answerState;
			answerState[answersConfigurationId].numericValue =
				e.target.value == ""
					? null
					: answerType == "NumericInt"
						? parseInt(e.target.value, 10)
						: parseFloat(e.target.value);
			this.setState({
				answerState: answerState
			}/*, this.checkQuestionAnswered*/);


			//if (this.answerProcessed[answersConfigurationId] !== true) {
				this.answerProcessed[answersConfigurationId] = false;
			//}
			this.answerSaved[answersConfigurationId] = false;
			this.props.onAnswersSavedChanged(false);


			this.delay(function (e) {
				if (!this.answerProcessed[answersConfigurationId]) {
					//this.props.onAnswerChanged(this.props.question, answerState[answersConfigurationId], answersConfigurationId);
					this.checkQuestionAnswered(false, answerState);
					this.answerProcessed[answersConfigurationId] = true;
				}
			}.bind(this), answersConfigurationId, 500)
		}
	}



	onDateInput(e, answersConfigurationId) {
		e.preventDefault();

		if (Date.parse(e.target.value) != this.state.answerState[answersConfigurationId].dateValue) {
			let answerState = this.state.answerState;
			answerState[answersConfigurationId].dateValue = Date.parse(e.target.value);
			this.setState({
				answerState: answerState
			}/*, this.checkQuestionAnswered*/);


			//if (this.answerProcessed[answersConfigurationId] !== true) {
				this.answerProcessed[answersConfigurationId] = false;
			//}
			this.answerSaved[answersConfigurationId] = false;
			this.props.onAnswersSavedChanged(false);


			this.delay(function (e) {
				if (!this.answerProcessed[answersConfigurationId]) {
					//this.props.onAnswerChanged(this.props.question, answerState[answersConfigurationId], answersConfigurationId);
					this.checkQuestionAnswered(false, answerState);
					this.answerProcessed[answersConfigurationId] = true;
				}
			}.bind(this), answersConfigurationId, 750)
		}
	}


	componentDidMount() {
		this.checkQuestionAnswered(true);
	}

	componentDidUpdate() {
		if (this.currentQuestionId != this.props.question.id) {
			this.currentQuestionId = this.props.question.id;

			this.inputReferences = this.inputReferences.filter((ref, index) => this.props.question.answersConfigurations.some((ac) => ac.id == index));

			this.answerProcessed = [];
			this.answerSaved = [];
			if (this.textAreaRegularSaveTimeout != null) {
				clearTimeout(this.textAreaRegularSaveTimeout);
				this.textAreaRegularSaveTimeout = null;
			}


			//this.answeredChecked = true;

			this.singleChoiceButtonsLocked = false;

			// TODO remove setState - this.setSavedAnswer() and this.checkQuestionAnswered(true) should be called in render(), this.setSavedAnswer(), most probably, only if question is changed
			let answerState = this.setSavedAnswer();
			this.setState({
				answerState: answerState,
				answered: false
			}, () => this.checkQuestionAnswered(true));

			$([document.documentElement, document.body]).animate({ scrollTop: 0 }, 250);

			this.iPhoneRerenderBugHelperCounter++;
		}
	}


	render() {
		if (this.props.question == null || this.state == undefined || this.state.answerState == undefined) {
			return;
        }

		// if result is generated in return statement (without intermediate variable), it does not work
		let result = html`
			<div class="questionnaire-question-view">
				<div class="questionnaire-question-text text-questionnaire-question-text" style=${"opacity: " + (this.iPhoneRerenderBugHelperCounter % 2 ? 1 : 0.99)}><${Markup} type="html" markup=${this.props.question.text} /></div>
				<div class="questionnaire-question-description text-questionnaire-question-description" style=${"opacity: " + (this.iPhoneRerenderBugHelperCounter % 2 ? 1 : 0.99)}><${Markup} type="html" markup=${this.props.question.description} /></div>
				<div class="questionnaire-question-answers-view">

					${this.props.question.answersConfigurations.map((answerConfiguration, aci) => {
						let text = answerConfiguration.text != null && answerConfiguration.text != "" ? html`<div class="questionnaire-question-answer-configuration-text text-questionnaire-question-answer-configuration-text"><${Markup} type="html" markup=${answerConfiguration.text} /></div>` : html``;


						let input;
						// onChange=${(e) => this.onOptionSelected(e, answerOption.id, answerConfiguration.id)}
						if (answerConfiguration.answerType == "Choice" && answerConfiguration.answerOptions != null && answerConfiguration.answerOptions.length > 0) {
							input = html`
								<div key=${aci} class="questionnaire-question-choice-answer-options-view">
									${answerConfiguration.answerOptions.map((answerOption, aoi) => {
										let answerChecked = typeof this.state.answerState[answerConfiguration.id] !== 'undefined' && this.state.answerState[answerConfiguration.id].optionsChecked.includes(answerOption.id) ? " questionnaire-question-choice-answer-option-checked" : "";
										let className = "questionnaire-question-choice-answer-option text-questionnaire-question-choice-answer-option" + answerChecked; // onClick="${()=>alert('')}"
										return html`
											<div key=${aoi} class=${className}>
												<input type="radio" name=${answerOption.id} id=${answerOption.id} checked=${answerChecked}/>
												<label for=${answerOption.id} onClick=${(e) => this.onOptionSelected(e, answerOption.id, answerConfiguration.id)}>${answerOption.text}</label>
											</div>
											`;
									}, this)}
								</div>`;
						}
						else if (answerConfiguration.answerType == "MultiChoice" && answerConfiguration.answerOptions != null && answerConfiguration.answerOptions.length > 0) {
							input = html`
								<div key=${aci} class="questionnaire-question-multichoice-answer-options-view">
									${answerConfiguration.answerOptions.map((answerOption, aoi) => {
										let answerChecked = typeof this.state.answerState[answerConfiguration.id] !== 'undefined' && this.state.answerState[answerConfiguration.id].optionsChecked.includes(answerOption.id);
										let answerCheckedClass = answerChecked ? " questionnaire-question-multichoice-answer-option-checked" : "";
										let className = "questionnaire-question-multichoice-answer-option text-questionnaire-question-multichoice-answer-option" + answerCheckedClass;
										// https://zpl.fi/hyphenation-in-react-native/
										return html`
											<div key=${aoi} class=${className}>
												<input type="checkbox" onChange=${(e) => this.onMultiOptionSelected(e, answerOption.id, answerConfiguration.id)} name=${answerOption.id} id=${answerOption.id} checked=${answerChecked}/>
												<label for=${answerOption.id}><${Markup} type="html" markup=${answerOption.text}/></label>
											</div>
										`;
									}, this)}
								</div>`;
						}
						else if (answerConfiguration.answerType == "SingleChoice") {
							let answerChecked = typeof this.state.answerState[answerConfiguration.id] !== 'undefined' && this.state.answerState[answerConfiguration.id].boolValue;
							let className = "questionnaire-question-multichoice-answer-option text-questionnaire-question-multichoice-answer-option" + (answerChecked ? " questionnaire-question-multichoice-answer-option-checked" : "");
							input = html`
									<div key=${aci} class=${className}>
										<input type="checkbox" onChange=${(e) => this.onSingleOptionSelected(e, answerConfiguration.id)} name=${answerConfiguration.id} id=${answerConfiguration.id} checked=${answerChecked}/>
										<label for=${answerConfiguration.id}><${Markup} type="html" markup=${answerConfiguration.placeholder}/></label>
									</div>
								`;
						}
						else if (answerConfiguration.answerType == "String") {
							let inputType = answerConfiguration.answerSpecialType == "Email" ? "email" : "text";
							input = html`<input ref=${ref => this.inputReferences[answerConfiguration.id] = ref} key=${aci} class="questionnaire-question-string-answer text-questionnaire-question-string-answer" type="${inputType}" value=${typeof this.state.answerState[answerConfiguration.id] !== 'undefined' ? this.state.answerState[answerConfiguration.id].textValue : ""} placeholder=${answerConfiguration.placeholder} onInput=${(e) => this.onStringInput(e, answerConfiguration.id)} required=${answerConfiguration.mandatory}/>`;
						}
						else if (answerConfiguration.answerType == "Text") {
							input = html`<textarea ref=${ref => this.inputReferences[answerConfiguration.id] = ref} key=${aci} class="questionnaire-question-text-answer text-questionnaire-question-text-answer" rows="5" cols="30" value=${typeof this.state.answerState[answerConfiguration.id] !== 'undefined' ? this.state.answerState[answerConfiguration.id].textValue : ""} placeholder=${answerConfiguration.placeholder} onInput=${(e) => this.onTextInput(e, answerConfiguration.id)} required=${answerConfiguration.mandatory} />`;
						}
						else if (answerConfiguration.answerType == "NumericInt" || answerConfiguration.answerType == "NumericFloat") {
							input = html`<input ref=${ref => this.inputReferences[answerConfiguration.id] = ref} key=${aci} class="questionnaire-question-numeric-answer text-questionnaire-question-numeric-answer" type="number" placeholder=${answerConfiguration.placeholder} value=${typeof this.state.answerState[answerConfiguration.id] !== 'undefined' ? this.state.answerState[answerConfiguration.id].numericValue : ""} onInput=${(e) => this.onNumericInput(e, answerConfiguration.id)} required=${answerConfiguration.mandatory} />`;
						}
						else if (answerConfiguration.answerType == "Date") {
							input = html`<input ref=${ref => this.inputReferences[answerConfiguration.id] = ref} key=${aci} class="questionnaire-question-date-answer text-questionnaire-question-date-answer" type="date" placeholder=${answerConfiguration.placeholder} value=${(typeof this.state.answerState[answerConfiguration.id] !== 'undefined' && this.state.answerState[answerConfiguration.id].dateValue != undefined && this.state.answerState[answerConfiguration.id].dateValue != null && !isNaN(this.state.answerState[answerConfiguration.id].dateValue)) ? (new Date(this.state.answerState[answerConfiguration.id].dateValue)).toISOString().substr(0, 10) : null} onInput=${(e) => this.onDateInput(e, answerConfiguration.id)} required=${answerConfiguration.mandatory} />`;
						}

						return html`
							<div class="questionnaire-question-answer-configuration-view">
								${text} 
								${input}
							</div>`;
					}, this)}

				</div>
				
				<div class="questionnaire-question-comment text-questionnaire-question-comment">
					${(typeof this.props.question.comment !== 'undefined' && this.props.question.comment != null && this.props.question.comment.trim() != "")
						? html`<${Markup} type="html" markup=${this.props.question.comment} />`
						: null
					}
				</div>
			</div>
		`;

		return result;
	}
}