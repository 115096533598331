import { Component } from "preact";
import { html } from "htm/preact";

import Markup from 'preact-markup';

import $ from 'jquery';
//import ReactPixel from 'react-facebook-pixel';

import Session from "../session.js";
import Question from "./question.js";
import { QuestionnaireReport } from "./questionnaireReport.js";
import { QuestionnaireInformation } from "./questionnaireInformation.js";
import { NavigationBar } from "./navigationBar.js";







export default class Questionnaire extends Component {
	PhazeEnum = {
		Initialization: 0,
		QuestionnaireInfo: 1,
		Questionnaire: 2,
		QuestionnaireResult: 3,
	}
	LoadingEnum = {
		None: 0,
		Report: 1,
	}







	constructor() {
		super();

		this.isMounted = false;

		this.sessionTools = new Session(),

		this.abortController = new AbortController();
		this.abortSignal = this.abortController.signal;



		this.allowMandatoryQuestionSkipping = false;
		this.containerReference = null;

		this.fbPixelOptions = {
			autoConfig: true, // set pixel's autoConfig
			debug: false, // enable logs
		};

		this.questionRef = null;

		this.answerSavePromises = [];

		this.popBrowserHistoryEventListener = this.popBrowserHistory.bind(this);

		this.state = {
			// Questionnaire is open in test mode - testing someones questionnaire without side effects
			test: false,
			// Current questionnaire has to be marked as test questionnaire - for exclusion from statistics and further deletion
			questionnaireTakeTest: false,

			loading: this.LoadingEnum.None,

			phaze: this.PhazeEnum.Initialization,
			// Current page of the info or report view
			page: 1,

			clientId: null,
			questionnaireTakeId: null,
			questionnaire: null,
			question: null,

			questionAnswered: false,
			questionValidationAvailable: false,
			questionAnswersSaved: false,

			// QuestionnaireStarted Facebook event should be fired after first question is answered, therefore when questionnaire is created or restored in Initialize(), this parameter is set so that after posting first question answers facebook StartQuestionnaire event is fired if firstQuestionAnswered is false (to avoid false fairing when first question answer is changed)
			// TODO Isn't it better to receive this information directly from Question/Put service method?
			//firstQuestionAnswered: false,
			// QuestionnaireFinished Facebook event should be fired when report is generated first time, therefore when questionnaire is created or restored in Initialize(), this parameter is set to avoid firing QuestionnaireCompleted event on report view of already completed questionnaire
			questionnaireCompleted: false,

			reportType: "Short",

			questionnaireResults: null,
			questionnaireReport: null,

			touchDevice: false,

			fbp: null,
			fbc: null,
			url: null
		};
	}







	componentDidMount() {
		this.isMounted = true;

		//ReactPixel.init('2253240321656053', {}, {
		//	autoConfig: true, // set pixel's autoConfig
		//	debug: false, // enable logs
		//});
		//if (typeof window.fbq !== 'undefined') window.fbq.disablePushState = true;

		// TODO Most probably this does not work as intended, as "You may not use the ref attribute on function components because they don’t have instances", parent element should be used instead
		let qc = this;
		$(this.containerReference).on("touchstart", function () {
			//if ($(this).css('background-color') == 'rgb(255, 0, 0)') $(this).css('background-color', 'rgb(0, 255, 0)'); else $(this).css('background-color', 'rgb(255, 0, 0)');
			//$(this).addClass("touch");
			qc.setState({ touchDevice: true });
			$(this).off("touchstart");
		});



		this.Initialize();



		window.addEventListener('popstate', this.popBrowserHistoryEventListener);
	}



	componentWillUnmount() {
		this.isMounted = false;

		if (this.abortController) this.abortController.abort(); // to unmount

		window.removeEventListener('popstate', this.popBrowserHistoryEventListener);
	}



	componentDidUpdate() {
	}







	pushBrowserHistory(view, page, questionNum, push = true) {
		let params = new URLSearchParams(window.location.search);

		if (view != null) params.set('view', view);
		else params.delete('view');

		if (page != null) params.set('page', page);
		else params.delete('page');

		if (questionNum != null) params.set('question', questionNum);
		else params.delete('question');

		params.set('r', this.state.reportType == "Short" ? "s" : this.state.reportType == "Full" ? "f" : "d");

		if (push) {
			history.pushState({ view: view, page: page, questionNum: questionNum }, null, `${location.pathname}?${params.toString()}`);
		} else {
			history.replaceState({ view: view, page: page, questionNum: questionNum }, null, `${location.pathname}?${params.toString()}`);
		}
	}

	popBrowserHistory(event) {
		// No further than last answered question
		// Potential problem with text field questions and delayed answer saving mechanism (unregistering events etc.)

		let view = null, page = null, questionNum = null;
		if (event.state == null) {
			let params = new URLSearchParams(window.location.search);
			view = params.get('view');
			page = params.get('page');
			questionNum = params.get('question');
		} else {
			view = event.state.view;
			page = typeof event.state.page !== 'undefined' && event.state.page !== null
				? (event.state.page == 'first' || event.state.page == 'last' ? event.state.page : parseInt(event.state.page, 10))
				: null;
			questionNum = typeof event.state.questionNum !== 'undefined' && event.state.questionNum !== null
				? (event.state.questionNum == 'first' || event.state.questionNum == 'last' ? event.state.questionNum : parseInt(event.state.questionNum, 10))
				: null;
		}



		if (view == null) {
			this.Initialize();
		}

		if (view == this.PhazeEnum.QuestionnaireInfo) {
			this.setState({
				phaze: this.PhazeEnum.QuestionnaireInfo,
				page: page != null ? page : 1
			});
		} else if (view == this.PhazeEnum.Questionnaire) {
			this.getNextQuestion(null, false, false, false, questionNum, true, false);
		} else if (view == this.PhazeEnum.QuestionnaireResult) {
			this.getResults(page != null ? page : 1, false);
        }
    }







	async Initialize() {
		let session = await this.sessionTools.prepareSession();
		if (!this.isMounted) return;


		let questionnaireIdOrSlug = session.questionnaireId ?? this.props.questionnaireSlug;

		// TODO if (response.ok)
		let data = await fetch(`../Questionnaire/CreateOrRestoreQuestionnaireTake/${session.clientId}/true/${session.language}/Q/${questionnaireIdOrSlug}${session.questionnaireTakeId ? `/QT/${session.questionnaireTakeId}` : ''}/${session.fbp}/${session.fbc}/${this.sessionTools.base64UrlEncode(window.location.toString())}`, { signal: this.abortSignal })
			.then(response => response.json());


		if (!this.isMounted) return;


		let questionnaireTakeInfo = data.item1;
		let eventId = data.item2;




		// If new questionnaireTake was created
		if (!questionnaireTakeInfo.restored) {
			this.setState({
				phaze: this.PhazeEnum.QuestionnaireInfo,
				clientId: session.clientId,
				questionnaireTakeId: questionnaireTakeInfo.id,
				questionnaire: questionnaireTakeInfo.questionnaire,
				test: session.testMode === true,
				//firstQuestionAnswered: false,
				questionnaireCompleted: false,
				reportType: session.reportType == "f" ? "Full" : "Short",
				fbp: session.fbp,
				fbc: session.fbc,
				ur: window.location.toString(),
			});

			this.pushBrowserHistory(this.PhazeEnum.QuestionnaireInfo, 1, null, false);
			if (session.testMode !== true && typeof window.fbq !== 'undefined') {
				//ReactPixel.trackCustom("QuestionnaireInitialised", { external_id: (await this.encodeSha256(session.clientId)), content_category: "Questionnaire", content_name: questionnaireTakeInfo.questionnaireSlug, custom_event_type: "QuestionnaireInitialised" });
				window.fbq('trackCustom', 'QuestionnaireInitialised', { content_category: "Questionnaire", content_name: questionnaireTakeInfo.questionnaireSlug, custom_event_type: "QuestionnaireInitialised" }, { eventID: eventId });
			}
		}



		// If questionnaireTake was restored and it is completed
		else if (questionnaireTakeInfo.completed) {
			this.setState({
				phaze: session.view === this.PhazeEnum.QuestionnaireInfo ? session.view : this.PhazeEnum.Initialization, // default: this.PhazeEnum.QuestionnaireResult
				page: (session.view === this.PhazeEnum.QuestionnaireInfo || session.view === this.PhazeEnum.QuestionnaireResult) && typeof session.viewPage !== 'undefined' && session.viewPage !== null ? session.viewPage : 1,
				clientId: session.clientId,
				questionnaireTakeId: questionnaireTakeInfo.id,
				questionnaire: questionnaireTakeInfo.questionnaire,
				test: session.testMode === true,
				questionnaireTakeTest: questionnaireTakeInfo.test,
				//firstQuestionAnswered: true,
				questionnaireCompleted: true,
				// If client opens report again (second or n.th time) after finishing questionnaire, full report should be rendered (first time - after answering last question - short report is prepared). If session or url sets forced short report (r="sf"), then prepare short
				reportType: session.reportType != "sf" ? "Full" : "Short",
				fbp: session.fbp,
				fbc: session.fbc,
				ur: window.location.toString(),
			}, () => {
				if (session.view == this.PhazeEnum.Questionnaire) {
					// https://stackoverflow.com/a/14794066
					let questionNumber = null;
					if (!isNaN(session.question)) {
						let value = parseFloat(session.question);
						if ((value | 0) === value) questionNumber = value;
                    }
					this.getNextQuestion(null, false, session.question === 'first', session.question === 'last', questionNumber, true, false);
				}
				if (session.view !== this.PhazeEnum.QuestionnaireInfo && session.view !== this.PhazeEnum.Questionnaire) {
					this.getResults(typeof session.viewPage !== 'undefined' && session.viewPage !== null ? session.viewPage : 1, false);
				}
			});



		// If questionnaireTake was restored and it not completed
		} else {
			// TODO use this.getNextQuestion() and take into account session.question value in order to show either last answered question or any question before last answered set by session.question
			let question = await fetch(`../Question/${session.clientId}/${questionnaireTakeInfo.questionnaireId}/${questionnaireTakeInfo.id}/${questionnaireTakeInfo.lastAnsweredQuestion !== null ? questionnaireTakeInfo.lastAnsweredQuestion : 0}/${session.language}`, { signal: this.abortSignal })
				.then(response => response.json());

			this.setState({
				phaze: this.PhazeEnum.Questionnaire,
				clientId: session.clientId,
				questionnaireTakeId: questionnaireTakeInfo.id,
				questionnaire: questionnaireTakeInfo.questionnaire,
				question: question,
				questionnaireTakeTest: questionnaireTakeInfo.test,
				test: session.testMode === true,
				//firstQuestionAnswered: questionnaireTakeInfo.lastAnsweredQuestion !== null,
				questionnaireCompleted: false,
				reportType: session.reportType == "f" ? "Full" : "Short",
				fbp: session.fbp,
				fbc: session.fbc,
				ur: window.location.toString(),
			});

			this.pushBrowserHistory(this.PhazeEnum.Questionnaire, null, question.order, false);

			if (session.testMode !== true && typeof window.fbq !== 'undefined') {
				//ReactPixel.trackCustom("QuestionnaireResumed", { external_id: (await this.encodeSha256(session.clientId)), content_category: "Questionnaire", content_name: questionnaireTakeInfo.questionnaireSlug, custom_event_type: "QuestionnaireResumed" }); // For tracking custom events
				window.fbq('trackCustom', 'QuestionnaireResumed', { content_category: "Questionnaire", content_name: questionnaireTakeInfo.questionnaireSlug, custom_event_type: "QuestionnaireResumed" }, { eventID: eventId });
			}
		}
    }










	render() {
		let blocks = [];

		if (this.state.phaze == this.PhazeEnum.QuestionnaireInfo) {
			blocks.push(html`<${QuestionnaireInformation}
							ref=${ref => this.containerReference = ref}
							touchDevice=${this.state.touchDevice}
							questionnaire=${this.state.questionnaire} 
							onQestionnaireBegin=${() => this.onQestionnaireBegin()} 
							page=${this.state.page}
							onChangePage=${(page) => { this.setState({ page: page }); this.pushBrowserHistory(this.PhazeEnum.QuestionnaireInfo, page, null, true); }}
						/>`);
		}
		if (this.state.phaze == this.PhazeEnum.Questionnaire && this.state.question != null) {
			blocks.push(html`
				<div class=${`questionnaire-questionnaire-view${(!this.state.touchDevice ? ' non-touch' : '')}`} ref=${ref => this.containerReference = ref}>
					<${Question}
						ref=${ref => this.questionRef = ref}
						question=${this.state.question} 
						onAnswerChanged=${(question, answerOptionsIds, answersConfigurationId) => this.saveAnswer(question, answerOptionsIds, answersConfigurationId)}
						singleChoiceLoadNextQuestion=${((this.props.nextOnChoiceSelect && (typeof this.state.question.proceedToNextQuestionAutomatically === 'undefined' || this.state.question.proceedToNextQuestionAutomatically == null || this.state.question.proceedToNextQuestionAutomatically)) || this.state.question.proceedToNextQuestionAutomatically) && !this.state.question.last && typeof this.state.question.answersConfigurations !== 'undefined' && this.state.question.answersConfigurations != null && this.state.question.answersConfigurations.length == 1 && this.state.question.answersConfigurations[0].answerType == "Choice"}
						onQuestionAnsweredChanged=${(answered, validationAvailable) => this.onQuestionAnsweredChanged(answered, validationAvailable)}
						onAnswersSavedChanged=${(saved) => this.onQuestionAnswersSavedChanged(saved)}
						/>
					<${NavigationBar} 
						NumberOfQuestions=${this.state.questionnaire.questionsCount}
						CurrentQuestionOrder=${this.state.question.order} 
						QuestionFirst=${this.state.question.first} 
						QuestionLast=${this.state.question.last}
						NumberOfSections=${this.state.questionnaire.sections.length}
						ActiveSectionOrder=${this.state.question.sectionOrder /*${this.state.questionnaire.sections.find(section => section.id == this.state.question.questionnaireSectionId).order}*/}
						NextQuestionNavigationAllowed=${this.state.questionAnswered}
						QuestionValidationAvailable=${this.state.questionValidationAvailable}
						onNextQuestion=${() => {
											if (this.state.questionAnswered) {
												if (this.state.questionAnswersSaved) this.getNextQuestion(this.state.question.id, true, false, false, null, false);
												else this.questionRef.saveAnswers.call(this.questionRef, () => {
													if (this.answerSavePromises.length > 0) {
														// Have to be done in order to avoid situation when several answers are saved before moving to next question and this next question is loaded before success of saveAnswer is called. In this case if this next question is yes/now, another question is loaded in saveAnswer success code. Therefore success of saving of all answers has to be ensured here before proceeding to the next question.
														$.when(...this.answerSavePromises).then(() => {
															this.getNextQuestion(this.state.question.id, true, false, false, null, false);
															// TODO Can't happen that saveAnswer Ajax success is fired after $when is resolved so another nextQuestion is called anyway?
															this.answerSavePromises = [];
														});
													}
													else this.getNextQuestion(this.state.question.id, true, false, false, null, false);
												});
											}
											else this.onShowQuestionValidation();
										}}
						onPreviousQuestion=${() => this.getNextQuestion(this.state.question.id, false, false, false, null, false)} 
						onResults=${() => this.getResults(1, true)}
						additionalNextButton=${this.props.additionalNextButtonNotChoice && (typeof this.state.question.answersConfigurations === 'undefined' || this.state.question.answersConfigurations == null || this.state.question.answersConfigurations.length != 1 || (this.state.question.answersConfigurations.length == 1 && this.state.question.answersConfigurations[0].answerType != "Choice"))}
						reportViewNavigationStripeButtonText=${this.state.questionnaire.reportViewNavigationStripeButtonText}
						reportViewButtonText=${this.state.questionnaire.reportViewButtonText}
						/>
				</div>`);
		}
		// onNextQuestion = ${ () => this.getNextQuestion(this.state.question.id, true, false, false, null, false) }

		if (this.state.phaze == this.PhazeEnum.QuestionnaireResult){
			blocks.push(html`
						<${QuestionnaireReport} 
							ref=${ref => this.containerReference = ref}
							touchDevice=${this.state.touchDevice}
							questionnaireResults=${this.state.questionnaireResults}
							reportType=${this.state.reportType}
							report=${this.state.questionnaireReport}
							language=${this.props.language} 
							onReturnToFirstQuestion=${() => this.getNextQuestion(null, false, true, false, null, true)}
							onReturnToLastQuestion=${() => this.getNextQuestion(null, false, false, true, null, true)}
							page=${this.state.page}
							onChangePage=${(page) => { this.setState({ page: page }); this.pushBrowserHistory(this.PhazeEnum.QuestionnaireResult, page, null, true); }}
							clientId=${this.props.debug ? this.state.clientId : null}
							questionnaireId=${this.props.debug ? this.state.questionnaire.id : null}
							questionnaireTakeId=${this.props.debug ? this.state.questionnaireTakeId : null}
							reportFinishedUrl=${this.state.questionnaire.destinationUrl + "?&r=" + (this.state.reportType == "Short" ? "s" : this.state.reportType == "Full" ? "f" : "d")}
							debug=${this.state.test}
							/>`);
		}



		if (this.state.test) {
			blocks.push(html`
							<div class="questionnaire-debug-view">
								<div>${this.state.questionnaireTakeId}</div>
								<div><button onClick=${() => this.setQuestionnaireTakeTest(!this.state.questionnaireTakeTest)}>${this.state.questionnaireTakeTest ? "Unset" : "Set"} as Test</button></div>
							</div>`);
			//if (this.state.phaze == this.PhazeEnum.QuestionnaireInfo || this.state.phaze == this.PhazeEnum.QuestionnaireResult) {
				//blocks.push(html``);
			//}
		}



		if (this.state.loading != this.LoadingEnum.None) { // || this.state.phaze == this.PhazeEnum.Initialization

			blocks.push(html`
							
							<div class="questionnaire-loading-view">
								<div><img class="questionnaire-loading-logo" src="/questionnaireimages/logo.svg" alt="Loading..." /></div>
								${this.state.loading == this.LoadingEnum.Report ? html`<div class="questionnaire-loading-description"><${Markup} type="html" markup=${this.state.questionnaire.reportViewWaitingText} /></div>` : null}
							</div>
							<div class="questionnaire-loading-overlay"></div>`
			);
        }

		return blocks;
	}



	onQestionnaireBegin() {
		//this.setState({
		//	phaze: this.PhazeEnum.Questionnaire,
		//}, () => this.getNextQuestion(null, false, true));

		this.getNextQuestion(null, false, true, false, null, true);
    }

	getNextQuestion(questionId = null, next = true, first = false, last = false, questionNum = null, setQuestionnairePhaze = false, pushBrowserHistory = true) {
		if (this.state.questionnaire == null) return;
		if (questionId != null && this.state.question.first && !next) return;
		if (questionId != null && this.state.question.last && next) return;

		$.ajax({
			url: questionNum != null
				? '../Question/' + this.state.clientId + '/' + this.state.questionnaire.id + '/' + this.state.questionnaireTakeId + "/" + questionNum + '/' + this.props.language
				: first
					? '../Question/First/' + this.state.clientId + '/' + this.state.questionnaire.id + '/' + this.state.questionnaireTakeId + '/' + this.props.language
					: last
						? '../Question/Last/' + this.state.clientId + '/' + this.state.questionnaire.id + '/' + this.state.questionnaireTakeId + '/' + this.props.language
						: next
							? '../Question/Next/' + this.state.clientId + '/' + this.state.questionnaire.id + '/' + this.state.questionnaireTakeId + '/' + this.props.language + (questionId != null && questionId != "" ? "/" + questionId : "")
							: '../Question/Previous/' + this.state.clientId + '/' + this.state.questionnaire.id + '/' + this.state.questionnaireTakeId + '/' + this.props.language + (questionId != null && questionId != "" ? "/" + questionId : ""),
			type: 'GET',
			context: this,
			success: function (data) {
				// TODO  deal with questions where available=false (if no next/previous question is available and service therefore returns requested question with available=false)
				if (!setQuestionnairePhaze) {
					this.setState({
						question: data
					});
				} else {
					this.setState({
						question: data,
						phaze: this.PhazeEnum.Questionnaire,
					});
				}
				this.pushBrowserHistory(this.PhazeEnum.Questionnaire, null, data.order, pushBrowserHistory);
			},
			error: function () {
				//called when there is an error
				//console.log(e.message);
			}
		});
	}



	// This function is called for every question answer configuration
	saveAnswer(question, questionAnswerState, answersConfigurationId) {
		let data = {
			AnswerOptions: questionAnswerState.optionsChecked,
			ValueBool: questionAnswerState.boolValue,
			Text: questionAnswerState.textValue,
			ValueInt: questionAnswerState.numericValue != null
				? isNaN(parseInt(questionAnswerState.numericValue, 10))
					? null :
					parseInt(questionAnswerState.numericValue, 10)
				: null,
			ValueFloat: questionAnswerState.numericValue != null
				? isNaN(parseFloat(questionAnswerState.numericValue))
					? null :
					parseFloat(questionAnswerState.numericValue)
				: null,
			ValueDateTime: (questionAnswerState.dateValue != null && typeof questionAnswerState.dateValue !== 'undefined' && !isNaN(questionAnswerState.dateValue)) ? (new Date(questionAnswerState.dateValue)).toISOString() : null,
		}


		if (question.id === null || typeof question.id !== 'string' || question.id.trim() === '') return;

		let promise = $.ajax({
			url: '../Question/' + this.state.clientId + '/' + this.state.questionnaire.id + '/' + this.state.questionnaireTakeId + '/' + question.id + '/' + answersConfigurationId + '/' + this.state.test + '/' + this.state.fbp + '/' + this.state.fbc + '/' + this.sessionTools.base64UrlEncode(window.location.toString()),
			type: 'POST',
			headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
			data: JSON.stringify(data),
			//dataType: 'json',
			context: this,
			success: async function (result) {
				// TODO if (result.item1 !== true)
				let facebookEvent = result.item2;
				let facebookEventId = result.item3;


				// If answered question is first question of the questionnaire and it has not been answered previously (so this is first time question is answered), fire Facebook QuestionnaireStarted event
				// TODO Isn't it better to get information if this is first question answered from service instead of using this.state.firstQuestionAnswered
				//if (!this.state.firstQuestionAnswered && question.first) {
					if (typeof facebookEvent === 'string' && facebookEvent.trim().toLowerCase() === 'QuestionnaireStarted'.toLowerCase() && !this.state.test && typeof window.fbq !== 'undefined') {
						//ReactPixel.trackCustom("QuestionnaireStarted", { external_id: (await this.encodeSha256(this.state.clientId)), content_name: "MenopausalTransition", custom_event_type: "QuestionnaireStarted" });
						window.fbq('trackCustom', 'QuestionnaireStarted', { content_category: "Questionnaire", content_name: this.state.questionnaire.slug, custom_event_type: "QuestionnaireStarted" }, { eventID: facebookEventId });
					}

				//	this.setState({
				//		firstQuestionAnswered: true,
				//	});
				//}

				if (((this.props.nextOnChoiceSelect && (typeof this.state.question.proceedToNextQuestionAutomatically === 'undefined' || this.state.question.proceedToNextQuestionAutomatically == null || this.state.question.proceedToNextQuestionAutomatically)) || this.state.question.proceedToNextQuestionAutomatically) && !this.state.question.last && typeof this.state.question.answersConfigurations !== 'undefined' && this.state.question.answersConfigurations != null && this.state.question.answersConfigurations.length == 1 && this.state.question.answersConfigurations[0].answerType == "Choice") {
					this.answerSavePromises = [];
					this.getNextQuestion(this.state.question.id, true);
				}
			},
			error: function () {
				//called when there is an error
				//console.log(e.message);
			}
		});

		this.answerSavePromises.push(promise);
	}


	onQuestionAnsweredChanged(answered, validationAvailable) {
		if (this.props.allowMandatoryQuestionSkipping) answered = true;

		this.setState({ questionAnswered: answered, questionValidationAvailable: validationAvailable })
	}

	onQuestionAnswersSavedChanged(saved) {
		this.setState({ questionAnswersSaved: saved })
	}


	// TODO implement without reference (props?, events?)
	onShowQuestionValidation() {
		if (this.state.phaze == this.PhazeEnum.Questionnaire && this.state.question != null && typeof this.questionRef !== 'undefined') {
			this.questionRef.reportValidity.call(this.questionRef);
		}
    }


	getResults(page, pushBrowserHistory = true) {
		this.setState({
			loading: this.LoadingEnum.Report,
		});

		$.ajax({
			url: `../Questionnaire/Results/${this.state.clientId}/${this.state.questionnaire.id}/${this.state.questionnaireTakeId}/true/${this.state.test}/${this.state.fbp}/${this.state.fbc}/${this.sessionTools.base64UrlEncode(window.location.toString())}`,
			type: 'GET',
			context: this,
		})
			.done(async function (data) {
				let questionnaireTakeInfo = data.item1;
				let questionnaireResults = questionnaireTakeInfo.results;

				let eventId = data.item2;

				if (!this.state.test && !this.state.questionnaireCompleted && typeof window.fbq !== 'undefined') {
					//ReactPixel.trackCustom("QuestionnaireCompleted", { external_id: (await this.encodeSha256(this.state.clientId)), content_name: "MenopausalTransition", custom_event_type: "QuestionnaireCompleted", result_type: questionnaireResults.Type });

					window.fbq('trackCustom', 'QuestionnaireCompleted', { content_category: "Questionnaire", content_name: this.state.questionnaire.slug, custom_event_type: "QuestionnaireCompleted", result_type: typeof questionnaireResults != 'undefined' && questionnaireResults != null ? questionnaireResults.Type : null }, { eventID: eventId });
				}

				this.setState({
					questionnaireCompleted: true,
				});



				$.ajax({
					url: `../Questionnaire/Report/${this.state.clientId}/${this.state.questionnaire.id}/${this.state.questionnaireTakeId}/${this.state.reportType}/${this.props.language}/${this.state.test}`,
					type: 'GET',
					context: this,
				})
					.done(function (questionnaireReport) {
						this.setState({
							phaze: this.PhazeEnum.QuestionnaireResult,
							loading: this.LoadingEnum.None,
							page: page,
							questionnaireResults: questionnaireResults,
							questionnaireReport: questionnaireReport,
						});

						this.pushBrowserHistory(this.PhazeEnum.QuestionnaireResult, page, null, pushBrowserHistory);
					}); //.fail(function (xhr) { });
			});//.fail(function (xhr) { });
	}


	setQuestionnaireTakeTest(test) {
		$.ajax({
			url: '../Questionnaire/SetQuestionnaireTakeTest/' + this.state.clientId + '/' + this.state.questionnaire.id + '/' + this.state.questionnaireTakeId + '/' + (test ? 'true' : 'false'),
			type: 'GET',
			context: this,
		})
			.done(function (result) {
				if (result == true) {
					this.setState({
						questionnaireTakeTest: test,
					});
				}
			}); //.fail(function (xhr) { });

	}



	async encodeSha256(string) {
		const utf8 = new TextEncoder().encode(string);
		const hashBuffer = await crypto.subtle.digest('SHA-256', utf8);
		const hashArray = Array.from(new Uint8Array(hashBuffer));
		const hashHex = hashArray
			.map((bytes) => bytes.toString(16).padStart(2, '0'))
			.join('');
		return hashHex;
	}
}
